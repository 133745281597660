// import React, { useEffect, useState } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';

function PostPoints(props) {
  if (props.from === null || props.to === null) {
    return
  }

  const { number } = useSpring({
    from: { number: props.from },
    number: props.to,
    delay: 0,
    config: { mass: 1, tension: 50, friction: 15 }
  });
  if (props.to - props.from === 1) {
    return <div className='font-semibold dark:text-neutral-200'>{props.to}</div>
  }
  return <animated.div className='font-semibold dark:text-neutral-200'>{number.to((n) => n.toFixed(0))}</animated.div>;
}

PostPoints.propTypes = {
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
}
export default PostPoints;
