import { getPresignedUrl } from "../model/presigned";
import { getPostMediaS3Bucket } from '../utilities/environment';

export async function uploadFileToS3(id, file, extension) {
  const S3_BUCKET = getPostMediaS3Bucket();
  const { url } = await getPresignedUrl(S3_BUCKET, id + '.' + extension, file.type);
  try {
    const response = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    });
    return response;
  } catch (error) {
    console.log(error);
  }
  
}
