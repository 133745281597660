import { React, useContext } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import { 
    StoryContext,
    SceneContext
} from '../routes/Root';

const modalStyle = {
  content: {
    flex: 1,
    top: '50%',
    left: '50%',
    width: '24rem',
    height: '5.5rem',
    marginRight: '0px',
    marginLeft: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: '22px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: localStorage.theme === 'DARK' ? 'rgba(29, 29, 29, 1)' : 'rgba(245, 245, 245, 1)',
    borderRadius: '8px',
    color: localStorage.theme === 'DARK' ? 'rgba(220, 220, 220, 1)' : 'rgba(23, 23, 23, 1)',
    border: '3px solid',
    borderColor: 'rgba(2, 233, 119, 1)',
  }, 
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  }
};

function StoryModal(props) {
  const { storyText, storyDismiss } = useContext(StoryContext);

  function handleClick(event) {
    event.preventDefault();
    storyDismiss();
  }

  return (
    <Modal 
        isOpen={storyText ? true : false}
        contentLabel="Minimal Modal Example"
        style={modalStyle}
    >
      {/* <div className=""> */}
        <div 
            className="flex h-full bg-light-backdrop hover:cursor-pointer justify-center
                dark:placeholder-neutral-500 dark:text-neutral-300 dark:bg-dark-backdrop items-center"
            onClick={handleClick}
        >
          <p className="font-semi text-center text-xl px-4">{storyText}</p> 
        </div>
      {/* </div> */}
    </Modal>
  );
}

StoryModal.propTypes = {
  // story: PropTypes.string.isRequired,
  // onDismiss: PropTypes.func.isRequired
}

export default StoryModal;
