import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// import { Outlet, useParams } from 'react-router-dom';
import TribunePostCardHeading from './TribunePostCardHeading.js';
import TribunePostCardContent from './TribunePostCardContent.js';
import PostModerationCardBottom from './PostModerationCardBottom.js';
import TribunePostCardSideBar from './TribunePostCardSideBar.js';
import { votePost } from '../model/posts.js';

import { UserContext } from '../routes/Root';

function PostModerationCard(props) {
  const [hasVoted, setHasVoted] = useState(Boolean(props.post.has_voted));
  const [points, setPoints] = useState(props.post.points);
  const [posterId, setPosterId] = useState();
  const [posterHandle, setPosterHandle] = useState();
  const [posterDisplayName, setPosterDisplayName] = useState();
  const { user, signOut, showSignUpModal } = useContext(UserContext);
  useEffect(() => {
    if (props.post) {
      props.post.type === 'user' ? setPosterId(props.post.author_id) : setPosterId(props.post.tribune_id);
      props.post.type === 'user' ? setPosterDisplayName(props.post.author_twitter_display_name) : setPosterDisplayName(props.post.tribune_twitter_display_name);
      props.post.type === 'user' ? setPosterHandle(props.post.author_twitter_handle) : setPosterHandle(props.post.tribune_twitter_handle);
    }
  }, [props.post]);
  
  useEffect(() => {
    setPoints(props.post.points);
  }, [props.post.points]);

  async function handleVote() {
    setHasVoted(true);
    setPoints(Number(points) + 1);
    try {
      const post = await votePost(props.post.id, user.id);
      setPoints(parseInt(post.points));
    } catch(error) {
      console.error(error.message);
      console.error(error.data);
    }
  }

  const postComponents = (
    <>
      <div className="flex flex-col flex-grow mb-3">
        <div className="flex">
          {props.feed &&<TribunePostCardSideBar 
              points={Number(points)}
              createdAt={props.post.created_at}
              hasVoted={hasVoted}
              onVoteClick={handleVote}
              postId={Number(props.post.id)}
              authorId={Number(posterId)}
              voteThresholdPercent={(points / (props.post.publish_points_threshold + 1)) * 100} // GIVE A REAL VALUE
          />}
            <div className="flex flex-col rounded-r flex-grow bg-light-backdrop dark:bg-dark-backdrop pl-5 pr-7 py-1 ml-1">
              <TribunePostCardHeading
                  id={Number(props.post.id)}
                  authorId={Number(posterId)}
                  authorHandle={posterHandle}
                  authorDisplayName={posterDisplayName}
                  timestamp={props.post.created_at}
                  tribuneHandle={props.post.tribune_handle}
                  outsideTribune={false}
                  onDeleteClick={props.onDeleteClick}
              />
              <TribunePostCardContent
                  id={props.post.id}
                  text={props.post.body}
                  mediaUrl={props.post.media_url}
                  hashtag={props.hashtag}
              />
              <PostModerationCardBottom 
                  id={props.post.id}
                  authorId={Number(posterId)}
                  points={Number(props.post.points)}
                  hasVoted={Boolean(props.post.has_voted)}
                  timestamp={props.post.created_at}
                  replyCount={props.post.reply_count}
                  onDeleteClick={props.onDeleteClick}
                  setPosts={props.setPosts}
                  posts={props.posts}
              />
            </div>
        </div>
      </div>
    </>
  );
  return (
    <>
      {postComponents}
    </>
  );
}

PostModerationCard.propTypes = {
  post: PropTypes.object.isRequired,
  hashtag: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func,
  posts: PropTypes.array.isRequired,
  setPosts: PropTypes.func.isRequired,
  outsideTribune: PropTypes.bool,
  feed: PropTypes.bool
};

export default PostModerationCard;
