import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { profilePictureUrl } from '../utilities/general';
import { getFriends } from '../model/FacebookAccounts';

import { UserContext } from '../routes/Root';
import { SignUpContext } from '../components/SignUpModal';

const welcomeSound = new Audio("/audio/welcome.wav");

function LoginWelcome(props) {
  const { user, setUser, setShowSignUpModal } = useContext(UserContext);
  const [acceptedBadge, setAcceptedBadge] = useState(false);
  const navigate = useNavigate();

  async function updateUserFriends() {
    const updatedUser = user;
    // const response = await getFriends();
    // user.facebookFriends = response.facebookFriends;
    setUser(updatedUser);
    localStorage.setItem('user', JSON.stringify(user));
  }
  
  useEffect(() => {
    updateUserFriends();
    navigate(localStorage.getItem('context'));
    // welcomeSound.play();
  }, [])



  return (
    <div className='flex flex-col items-center flex-start z-40'>
      {user && user.is_community && !acceptedBadge ? (
        <div className='flex flex-col items-center w-[16rem] text-center z-40'>
          <img className='mt-3 mb-3 h[9rem] w-[9rem] border-[3px] border-neutral-300 dark:border-netural-800 rounded-lg bg-gray-50' src='/images/item-5.png' />
          <div className='font-medium'>
            {String("You've earned the community badge ❤️ ... Use in giveaways to multiply bonus entries! 🎁")}
          </div>
          <button 
              onClick={() => {setAcceptedBadge(true)}}
              className='dark:bg-neutral-200 dark:text-neutral-900 font-semibold py-2 px-6 bg-neutral-500 text-neutral-100
                rounded-lg hover:bg-primary dark:hover:bg-primary dark:hover:text-neutral-900 mt-3 hover:text-neutral-50'>
            {/* Let&apos;s go */}
            Okay!
          </button>
        </div>  
      ) : (
        <>
          <p className='text-xl mt-6 mb-3 flex-start font-bold'>{user.display_name}</p>
          <img src={profilePictureUrl(user.id)} alt="" className="h-[10rem] w-[10rem] border-4 border-primary rounded-full bg-gray-50" />
          <button 
              onClick={() => {setShowSignUpModal(false); props.setProgress(1); }}
              className='dark:bg-neutral-200 dark:text-neutral-900 font-semibold py-2 px-6 mt-6 bg-neutral-500 text-neutral-100
                rounded-lg hover:bg-primary dark:hover:bg-primary dark:hover:text-neutral-900 hover:text-neutral-50 transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300'>
            {/* Let&apos;s go */}
            Enter Tribune
          </button>
        </>
      )}
    </div>
  )
}

LoginWelcome.propTypes = {
  setProgress: PropTypes.func.isRequired
}

export default LoginWelcome;
