import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

import LIGHTING from '../scenes/lighting';
import { LightingContext } from '../routes/Root';
import { UserContext } from '../routes/Root';

import PlayerAction from './PlayerAction';

function FlashlightPlayerAction(props) {
  const flashlightSound = new Audio("/audio/flashlight.wav");
  const { lightingTheme, toggleLighting } = useContext(LightingContext);

  const [morseCodeString, setMorseCodeString] = useState('');
  const [awaitingDot, setAwaitingDot] = useState(false);
  const [awaitingDash, setAwaitingDash] = useState(false);
  const morseCodeTimeouts = useRef([]);
  const morseCodeResetTimeouts = useRef([]);

  function sendMorseCode() {
    setAwaitingDot(true);
    setAwaitingDash(true);
    setAwaitingDash(true);
    const dotTimeout = setTimeout(() => setAwaitingDot(false), 800);
    const dashTimeout = setTimeout(() => setAwaitingDash(false), 3000);
    const resetTimeout = setTimeout(() => setMorseCodeString(''), 8000);
    morseCodeTimeouts.current.push(dotTimeout);
    morseCodeTimeouts.current.push(dashTimeout);
    morseCodeResetTimeouts.current.push(resetTimeout);
  }

  function receiveMorseCode() {
    if (awaitingDot) {
      setMorseCodeString(morseCodeString + '0');
    } else if (awaitingDash) {
      setMorseCodeString(morseCodeString + '1');
    } 
    morseCodeTimeouts.current.forEach((timeout) => clearTimeout(timeout));
    morseCodeTimeouts.current.length = 0;
    morseCodeResetTimeouts.current.forEach((timeout) => clearTimeout(timeout));
    morseCodeResetTimeouts.current.length = 0;
  }

  function toggleLightSwitch() {
    // flashlightSound.play();
    if (lightingTheme === LIGHTING.DARK) {
      sendMorseCode();
    } else {
      receiveMorseCode();
    }
    toggleLighting();
  }

  useEffect(() => {
    if (morseCodeString.includes('000111000')) {
      setMorseCodeString('');
      alert('SOS');
    }
  }, [morseCodeString])

  function onClick(event) {
    // event.preventDefault(); // Uncomment to not auto-close menu on toggle
    toggleLightSwitch();
    props.onClick && props.onClick();
  }

  const action = {
    name: props.text ? props.text : lightingTheme === LIGHTING.DARK? 'Flashlight on' : 'Flashlight off',
    onClick: onClick,
    route: '#',
    playerType: props.playerType,
    image: '/images/flashlight.png'
  }

  return (
    <PlayerAction action={action} />
  );
}

FlashlightPlayerAction.propTypes = {
  text: PropTypes.string,
  playerType: PropTypes.string,
  onClick: PropTypes.func
};

export default FlashlightPlayerAction;
