import { React, useState, useContext, useEffect, useRef, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal } from "react-responsive-modal";
import autoAnimate from '@formkit/auto-animate'


import { LightingContext, UserContext } from '../routes/Root';
import { generateStyle } from '../utilities/modal';
import { isLocalEnv } from '../utilities/environment';

const SignUpContext = createContext('signUp');

function LandingModal(props) {
  const navigate = useNavigate();

  const { lightingTheme } = useContext(LightingContext);

  const [ step, setStep ] = useState(1);
  const [ isOpen, setIsOpen ] = useState(!localStorage.getItem('dismissed') || (!props.isLoggedIn && localStorage.getItem('dismissed') === 'false'));

  function advanceStep() {
    setStep(step + 1);
  }

  useEffect(() => {
    if (localStorage.getItem('dismissed') === 'true') {
      setIsOpen(false);
    } else if (!props.isLoggedIn || !localStorage.getItem('dismissed')) {
      setIsOpen(true);
    }
  }, [props.isLoggedIn]);
  
  return (
    <Modal 
        open={false}
        contentLabel="Minimal Modal Example"
        showCloseIcon={false}
        blockScroll={true}
        classNames={{
          // sm:top-[0%] md:top-[4%]  
          modal: `inset-x-0 mx-auto !p-0 h-[28rem] border-[0.5px] border-neutral-700 dark:border-neutral-800
            bg-light-backdrop dark:bg-dark-backdrop rounded-lg transform
            border-neutral-400 text-neutral-800 dark:text-neutral-300 w-screen sm:w-[26rem]`,
          overlay: `bg-neutral-800 bg-opacity-80`,
        }}
    >
      {/* <div className='text-center font-bold m-5 text-2xl'>
        Welcome to Tribune.link
      </div> */}
      <div className='flex flex-col items-center mt-5'>
        {/* { step == 1 && (
        <>
        <img className='w-[13rem] h-[13rem]' src='/images/icon.png' />
        <div className='mt-3 text-center font-semibold'>
          Tribune.link
        </div>
        <div className='mx-6 w-[15rem] absolute bottom-20 text-center'>
          Tribune is an place where anyone can add to the conversation. Just a few things to know...
        </div>
        </>
        )
        } */}
        { step == 1 && (
        <>
        <img className='w-[11rem] h-[11rem]' src='/images/icon.png' />
        <div className='my-3 text-center font-semibold'>
          Tribune.link
        </div>
        <div className=''>
          <div>
            <span className='font-semibold'>1.</span> Submit a post to be featured 
          </div>
          <div>
            <span className='font-semibold'>2.</span> Get enough community votes
          </div>
          <div>
            <span className='font-semibold'>3.</span> Your post is published to X
          </div>
          <div>
            <span className='font-semibold'>4.</span> You earn a token for each published post
          </div>
          <div>
            <span className='font-semibold'>5.</span> Earn enough tokens to level up your power
          </div>
        </div>
        </>
        )
        }
        {/* { step == 2 && (
        <>
        <img className='h-[13rem]' src='/images/posting-tutorial.png' />
        <div className='mt-3 text-center font-semibold'>
          Posting
        </div>
        <div className='mx-6 w-[13rem] absolute bottom-20 text-center'>
          Choose to submit a Self Post or a Tribune Post
        </div>
        </>
        )
        }
        { step == 3 && (
        <>
        <img className='h-[13rem]' src='/images/self-tutorial.png' />
        <div className='mt-3 text-center font-semibold'>
          Self Posts
        </div>
        <div className='mx-6 w-[13rem] absolute bottom-20 text-center'>
          Choose Self Post if you would like to post as, well, yourself. Self Posts 
          get featured by Tribune
        </div>
        </>
        )
        }
        { step == 4 && (
        <>
        <img className='h-[13rem]' src='/images/tribune-tutorial.png' />
        <div className='mt-3 text-center font-semibold'>
          Tribune Posts
        </div>
        <div className='mx-6 w-[13rem] absolute bottom-20 text-center'>
          Choose Tribune Post if you would like to post contribute more directly to 
          the Tribune and earn tokens for doing so
        </div>
        </>
        )
        }
        { step == 5 && (
        <>
        <img className='h-[13rem]' src='/images/voting-tutorial.png' />
        <div className='mt-3 text-center font-semibold'>
          Voting
        </div>
        <div className='mx-6 w-[13rem] absolute bottom-20 text-center'>
          Before your post gets published, it must receive enough votes. Once the 
          power meter fills, your post just needs to be approved by a moderator, then your X post is live!
        </div>
        </>
        )
        } */}
        <div 
            onClick={() => {localStorage.setItem('dismissed', true); setIsOpen(false)}}
            className='rounded-xl font-semibold cursor-pointer text-center
              py-2 w-[95%] mx-3 hover:dark:text-black border-neutral-400 dark:border-neutral-600
              dark:text-neutral-200 hover:bg-primary border-2 absolute bottom-5'>
          Okay!
        </div>
      </div>
    </Modal>
  );
}

LandingModal.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
}

export default LandingModal;
