/*global FB*/

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { PlayerContext } from '../routes/Root';
import { UserContext } from '../routes/Root';
import { SignUpContext } from '../components/SignUpModal';
import { LightingContext } from '../routes/Root';
import PlayerAction from './PlayerAction';
import { goToTwitterAuth } from '../utilities/twitter';

import { v4 as uuidv4 } from 'uuid';

function SignInPlayerAction(props) {
  const { setShowSignUpModal } = useContext(UserContext);

  async function onClick() {
    // setShowSignUpModal(true);
    goToTwitterAuth('user');
  }

  const action = {
    name: 'Login with X',
    onClick: onClick,
    // route: '/',
    image: '/images/enter.png'
  }

  return (
    <PlayerAction action={action} />
  );
}

SignInPlayerAction.propTypes = {
};

export default SignInPlayerAction;
