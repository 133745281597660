export function isProdEnv() {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    return true;
  }
  return false;
}

function isDevEnv() {
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    return true;
  }
  return false;
}

export function isLocalEnv() {
  if (process.env.REACT_APP_NODE_ENV === 'local') {
    return true;
  }
  return false;
}

export function getAPIDomain() {
  if (isProdEnv()) {
    return 'api.tribune.link';
  } else if (isDevEnv()) {
    return 'api-dev.tribune.link';
  } else if (isLocalEnv()) {
    return 'tribune.local:3001'
  } else {
    throw new Error('unknown environment');
  }
}

export function getProfilePictureS3Bucket() {
  if (isProdEnv()) {
    return 'link.tribune.profile-pictures';
  } else if (isDevEnv()) {
    return 'link.tribune.dev.profile-pictures';
  } else if (isLocalEnv()) {
    return 'link.tribune.dev.profile-pictures'
  } else {
    throw new Error('unknown environment');
  }
}

export function getGiveawayPhotoS3Bucket() {
  if (isProdEnv()) {
    return 'link.tribune.giveaway-pictures';
  } else if (isDevEnv()) {
    return 'link.tribune.dev.giveaway-pictures';
  } else if (isLocalEnv()) {
    return 'link.tribune.dev.giveaway-pictures'
  } else {
    throw new Error('unknown environment');
  }
}

export function getFacebookPictureS3Bucket() {
  if (isProdEnv()) {
    return 'link.tribune.facebook-pictures';
  } else if (isDevEnv()) {
    return 'link.tribune.dev.facebook-pictures';
  } else if (isLocalEnv()) {
    return 'link.tribune.dev.facebook-pictures';
  } else {
    throw new Error('unknown environment');
  }
}

export function getPostMediaS3Bucket() {
  if (isProdEnv()) {
    return 'link.tribune.post-media';
  } else if (isDevEnv()) {
    return 'link.tribune.dev.post-media';
  } else if (isLocalEnv()) {
    return 'link.tribune.dev.post-media'
  } else {
    throw new Error('unknown environment');
  }
}

export function getWebDomain() {
  if (isProdEnv()) {
    return 'tribune.link';
  } else if (isDevEnv()) {
    return 'dev.tribune.link';
  } else if (isLocalEnv()) {
    return 'tribune.local'
  } else {
    throw new Error('unknown environment');
  }
}
