import { React, Component } from "react";
import {
  Route,
  Routes,
  BrowserRouter
} from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute.js";
import Root from "./routes/Root.js";
import Home from "./routes/Home.js";
import MyTribunes from "./routes/MyTribunes.js";
import Discover from "./routes/Discover.js";
import ForumChannel from "./routes/ForumChannel.js";
import CreateGiveawayPost from "./routes/CreateGiveawayPost.js";
import GiveawaysChannel from "./routes/GiveawaysChannel.js";
import GiveawaysModerationChannel from "./routes/GiveawaysModerationChannel.js";
import ModerationChannel from "./routes/ModerationChannel.js";
import InventoryChannel from "./routes/InventoryChannel.js";
import GiveawayEntry from "./routes/GiveawayEntry.js";
import Giveaway from "./routes/Giveaway.js";
import Post from "./routes/Post.js";
import Admin from "./routes/Admin.js";
import Authenticated from "./routes/Authenticated.js";
import Privacy from "./routes/Privacy.js";
import GiveawayTerms from "./routes/GiveawayTerms.js";
import Tribune from "./routes/Tribune.js";
import TribunePosts from "./routes/TribunePosts.js";  
import SignIn from "./routes/SignIn.js";
import SignUp from "./routes/SignUp.js";
import Profile from "./routes/Profile.js";
import Verify from "./routes/Verify.js";
import PropTypes from 'prop-types';
import "./App.css";

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Root />}>
            <Route index element={<Discover />} />

            <Route 
              path="/g/:giveawayId" 
              element={<Giveaway />}
            >
              <Route path="/g/:giveawayId/" element={<GiveawayEntry />} />
              <Route element={<Authenticated />}>
                <Route path="/g/:giveawayId/post" element={<CreateGiveawayPost />} />
              </Route>
            </Route>
            </Route>


          <Route path="/signin" element={<Root signin={true}/>} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/giveaway-terms/:giveawayId" element={<GiveawayTerms />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;


  {/* <Route path="/t/:tribuneHandle" element={<Tribune />}>
    <Route path="/t/:tribuneHandle/forum" element={<TribunePosts />}>
      <Route index element={<ForumChannel />} />
      <Route path="/t/:tribuneHandle/forum/giveaways" element={<GiveawaysChannel />} />
      <Route path="/t/:tribuneHandle/forum/giveaways/:giveawayId" element={<GiveawayEntry />} />
      <Route path="/t/:tribuneHandle/forum/inventory" element={<InventoryChannel />} />
      <Route path="/t/:tribuneHandle/forum/moderation" element={<ModerationChannel />} />
      <Route path="/t/:tribuneHandle/forum/giveaways-moderation" element={<GiveawaysModerationChannel />} />
    </Route>
    
    <Route path="/t/:tribuneHandle/post/:id" element={<Post />} />
  </Route> */}
{/* </Route> */}
