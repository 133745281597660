import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import ConnectPhoneButton from './ConnectPhoneButton';
import ConnectPhoneInput from './ConnectPhoneInput';
import ConnectPhoneVerify from './ConnectPhoneVerify';

function ConnectPhone(props) {
  const [stage, setStage] = useState('BUTTON');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [component, setComponent] = useState(
      <ConnectPhoneButton setStage={setStage} setPhoneNumber={setPhoneNumber} />
  );

  // Update with each step
  useEffect(() => {
    if (stage === 'BUTTON') {
      setComponent(<ConnectPhoneButton
          setStage={setStage}
      />);
    } else if (stage === 'INPUT') {
      props.setActionImage('/images/cancel.png');
      props.setActionOnClick(() => props.clicky);
      setComponent(<ConnectPhoneInput
          setStage={setStage}
          setPhoneNumber={setPhoneNumber}
      />);
    } else if (stage === 'VERIFY') {
      setComponent(<ConnectPhoneVerify
          setStage={setStage}
          phoneNumber={phoneNumber}
          setActionImage={props.setActionImage}
          setActionOnClick={props.setActionOnClick}
      />);
    }
}, [stage]);


  return (
    <div className='flex items-center justify-center'>
      {component}
    </div>
  )
}

ConnectPhone.propTypes = {
  loggingInUserId: PropTypes.number.isRequired,
  setActionImage: PropTypes.func.isRequired,
  setActionOnClick: PropTypes.func.isRequired,
  clicky: PropTypes.func.isRequired,
}

export default ConnectPhone;
