import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function GiveawayAbout(props) {

  const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };

  return (
    <div className='w-full pl-4 pb-1 border-b border-neutral-300 dark:border-neutral-700'>
      <div className='text-xl font-bold dark:text-neutral-100 mb-1'>{props.giveaway.title}</div>
      <div className='leading-4 mb-2'>
        <div className='dark:text-neutral-100 mb-1'>{props.giveaway.description}</div>
        <div className='text-sm font-light text-neutral-600 dark:text-neutral-300 '>
          {/* Starts {(new Date(giveaway.created_at)).toLocaleDateString('en-US', options)} {' • '}  */}
            {'Until ' + (new Date(props.giveaway.end_at)).toLocaleDateString('en-US', dateOptions)}</div>
        {/* <div className='text-sm dark:text-neutral-300 '>{giveawayId > 9 ? '🇺🇸 US 🇬🇧 UK 🇨🇦 CA ': '🇺🇸 US '}Sweepstakes {isIOS}</div> */}
        
        <Link
            to={{
              pathname: '/giveaway-terms/' + props.giveaway.id,
            }}
            
            className='leading-[8px] font-light text-sm dark:text-neutral-400 text-neutral-600 cursor-pointer hover:text-neutral-900 hover:dark:text-neutral-100'>
          See terms
        </Link>
      </div>
    </div>
  );
}

GiveawayAbout.propTypes = {
  giveaway: PropTypes.object,
  // giveawayTitle: PropTypes.string,
  // giveawayDescription: PropTypes.string,
  // giveawayEndAt: PropTypes.string
}

export default GiveawayAbout
