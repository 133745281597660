// import React, { useEffect, useState } from 'react';
import React from 'react';
import PropTypes from 'prop-types';

function ProgressBar(props) {
  let borderClass='border-solid mb-2.5 w-7 h-[58px] border-2 rounded-lg bg-light-backdrop dark:bg-dark-backdrop flex flex-col-reverse';
  let progressClass = 'px-0 py-0 mx-0 my-0 border-solid border-2 rounded-b-sm transition-height duration-1000 ease-out';
  if (props.progressPercentage >= 100) {
    progressClass += ' rounded-t-sm';
  }

  if (props.votingExpired) {
    progressClass += ' dark:bg-neutral-700 bg-neutral-300 dark:border-neutral-700 border-neutral-300';
    borderClass += ' dark:border-neutral-700 border-neutral-300'
  } else {
    borderClass += ' border-primary';
    progressClass += ' bg-primary border-primary';
  }

  return (
    <div className={borderClass}>
      <div className={progressClass} style={{ height: Math.min(props.progressPercentage, 100).toString() + '%' }}></div>
    </div> 
  );
}

ProgressBar.propTypes = {
  progressPercentage: PropTypes.number.isRequired,
  votingExpired: PropTypes.bool.isRequired,
}
export default ProgressBar;