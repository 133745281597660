import { React, useState, useContext, useEffect, useRef, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import autoAnimate from '@formkit/auto-animate'

import { LightingContext, UserContext } from '../routes/Root';
import { generateStyle } from '../utilities/modal';
import { countTrue } from '../utilities/general';
import ConnectSocials from './ConnectSocials';
import ConnectPhone from './ConnectPhone';
import LoginWelcome from './LoginWelcome';

import { facebookLogout } from '../utilities/facebook';

const SignUpContext = createContext('signUp');

Modal.setAppElement(document.getElementById('root'));

function SignUpModal(props) {
  const navigate = useNavigate();

  function clicky() {
    navigate(location.pathname);
    props.setShowModal(false);
    setProgress(1);
  }
  const { user, setUser } = useContext(UserContext);
  const [progress, setProgress] = useState(1);
  const [heading, setHeading] = useState('Connect Socials');
  const [actionImage, setActionImage] = useState('/images/cancel.png');
  const [actionOnClick, setActionOnClick] = useState(() => clicky);
  
  const [loggingInUser, setLoggingInUser] = useState(null);
  const [twitterLoading, setTwitterLoading] = useState(false);
  const [twitterConnected, setTwitterConnected] = useState(false)
  const [facebookConnected, setFacebookConnected] = useState(false);
  const [phoneConnected, setPhoneConnected] = useState(false);
  
  const [progressWidth, setProgressWidth]= useState('transition-all ease-linear duration-300 flex justify-center border-b border-neutral-700 w-[130px] h-1 bg-primary transition duration-700');
  const { lightingTheme } = useContext(LightingContext);
  const [headingIcon, setHeadingIcon] = useState('/images/link.png');
  
  const [connectComponent, setConnectComponent] = useState();
  const [style, setStyle] = useState(generateStyle(lightingTheme));

  // Fix animation!!
  const body = useRef(null)
  useEffect(() => {
    body.current && autoAnimate(body.current)
  }, [body])
  
  // Update modal on lighting toggle
  useEffect(() => {
    setStyle(generateStyle(lightingTheme));
  }, [lightingTheme]);
  
  useEffect(() => {
    let progress = 1;
    if (twitterConnected) progress++;
    if (twitterConnected && facebookConnected) progress++;
    if (twitterConnected && facebookConnected && phoneConnected) {
      if (!user) setUser(loggingInUser);
      progress++;
    }
    setProgress(progress);
  }, [twitterConnected, facebookConnected, phoneConnected]);

  useEffect(() => {
    if (progress < 3) {
      setHeading('Connect Socials');
      setHeadingIcon('/images/link.png');
      setConnectComponent(<ConnectSocials loggingInUser={loggingInUser} setLoggingInUser={setLoggingInUser} />);
    }

    if (progress === 0) {
      setProgressWidth('flex justify-center h-1 bg-primary w-0')
    } else if (progress === 1) {
      setProgressWidth('flex justify-center h-1 bg-primary w-1/4')
    } else if (progress === 2) {
      setProgressWidth('transition-all ease-linear duration-300 flex justify-center h-1 bg-primary w-1/2')
    } else if (progress === 3) {
      setHeading('Secure Account');
      setHeadingIcon('/images/padlock.png');
      setConnectComponent(<ConnectPhone
          setActionImage={setActionImage}
          setActionOnClick={setActionOnClick}
          clicky={clicky}
          loggingInUserId={loggingInUser.id}
      />);
      setProgressWidth('transition-all ease-linear duration-300 flex justify-center h-1 bg-primary w-3/4')
    } else if (progress === 4) {
      setHeading("Welcome!");
      setHeadingIcon('/images/pillar.png');
      setConnectComponent(<LoginWelcome setProgress={setProgress} />);
      setProgressWidth('transition-all ease-linear duration-300 flex justify-center h-1 bg-primary w-full')
    }
  }, [progress, user]);

  // useEffect(() => {
  //   if (!user && progress === 4) {
  //     setProgress(1);
  //   }
  // }, [])

  return (
    <SignUpContext.Provider
        value={{
          twitterLoading, setTwitterLoading,
          twitterConnected, setTwitterConnected,
          phoneConnected, setPhoneConnected,
          facebookConnected, setFacebookConnected,
          setShowModal: props.setShowModal
        }}
    >
      <Modal 
          isOpen={props.showModal}
          contentLabel="Minimal Modal Example"
          style={style}
      >
        <div className="overflow-hidden">
          <div className="flex justify-center pb-4">
            <img className='absolute left-5 w-6 h-6 mb-1' src={headingIcon} />
            <div className="text-xl font-bold">{heading} </div>
            {progress < 4 && 
              <img className='absolute right-5 w-5 h-5 mb-1 cursor-pointer' src={actionImage} onClick={() => {actionOnClick();}} />
}
            {/* {progress < 4 && <button className="absolute right-5 font-bold" 
                onClick={() => {props.setShowModal(false); navigate(location.pathname); facebookLogout();}}><img src={actionImage} /></button>} */}
          </div>
          <div className={progressWidth}></div>
          
          <div ref={body} className='flex z-20 h-[295px] justify-center'>
            {connectComponent}
          </div>
        </div>
      </Modal>
    </SignUpContext.Provider>
  );
}

SignUpModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
}

export {
  SignUpContext
};
export default SignUpModal;
