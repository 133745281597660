import { React, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import { UserContext } from '../routes/Root';

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

function AcknowledgeModal(props) {
  const { user } = useContext(UserContext);
  const [ showModal, setShowModal ] = useState(props.showModal);

  async function handleAck(event) {
    localStorage.setItem('ackEntry', true);
    props.setAcked(true);
    setShowModal(false);
  }

  useEffect(() => {
    setShowModal(props.showModal);
  }, [props.showModal])

  return (
    <Modal 
        open={showModal}
        contentLabel="Minimal Modal Example"
        showCloseIcon={false}
        blockScroll={true}
        classNames={{
          modal: `inset-x-0 mx-auto !p-0  border-[0.5px] border-neutral-700 dark:border-neutral-800
            bg-light-backdrop dark:bg-dark-backdrop rounded-lg top-[10rem] transform
            border-neutral-400 text-neutral-800 dark:text-neutral-300 w-screen sm:w-4/5 md:w-3/4 lg:max-w-[40rem]`,
          overlay: `bg-neutral-800 bg-opacity-80`,
        }}
    >
      <div className='p-4'>
        <div className='text-2xl font-bold mb-1'>Did you enter via X first?</div>
        <div className='text-lg leading-6'>
          To get bonus entries from items, you must enter via X (like, repost, reply, etc.) BEFORE clicking acknowledge. <b>If you do not enter
          via X first, you will only receive 1 entry.</b>
        </div>
        <Link
          to={{
            pathname: '/giveaway-terms/' + props.giveawayId,
          }}
          className='text-sm mt-2 mb-5 dark:text-neutral-400 text-neutral-600 cursor-pointer hover:text-neutral-900 hover:dark:text-neutral-100'>
            See terms for details
        </Link>
        <div 
            className='cursor-pointer mt-4 p-3 bg-primary font-bold rounded-md text-black text-center'
            onClick={handleAck}>
           Acknowledge
        </div>
      </div>
    </Modal>
  );
}

AcknowledgeModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setAcked: PropTypes.func.isRequired,
  giveawayId: PropTypes.number.isRequired
}

export default AcknowledgeModal;
