import { React, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import { UserContext } from '../routes/Root';
import { Modal } from "react-responsive-modal";

import PhoneInput from '../components/PhoneInput';
import PhoneVerify from '../components/PhoneVerify';

function SubscribeToTextModal(props) {
  const { user } = useContext(UserContext);
  // const [ showModal, setShowModal ] = useState(props.showModal);
  const [ phone, setPhone ] = useState();
  const [ stage, setStage ] = useState('input');

  function onPhoneVerified() {
    props.setShowModal(false);
    props.onComplete();
  }

  let render;
  if (stage === 'input') {
    render = (
      <>
        <div className='text-2xl text-center font-bold mb-1'>Turn on text notifications</div>
        <div className='mb-1'>
          Receive SMS messages notifying you of new giveaways 🎁
        </div>
        <PhoneInput onSubmit={() => setStage('verify')} setPhoneNumber={setPhone} />
        <div className='text-sm mb-1 mt-2'>
          By submitting this form and signing up for texts, 
          you consent to receive marketing text messages (e.g. promotions, sweepstakes alerts) from Twilio autodialer. 
          Consent is not a condition of purchase. Msg & data rates may apply. Msg frequency varies. Unsubscribe 
          at any time by replying &quot;STOP&quot;
        </div>
        <p className='text-sm'>See <a href='/privacy' className='font-italic'>privacy policy</a> and <a href='https://s3.amazonaws.com/link.tribune.documents/tos.pdf'>terms of service</a></p>

        {/* <div className='flex gap-x-1'>

          <div className='text-sm font-italic'>See </div>
          <Link
            to={'/privacy'}
            className='text-sm font-italic dark:text-neutral-400 text-neutral-700 cursor-pointer hover:text-black hover:dark:text-white'>
              privacy policy
          </Link>
          <div className='text-sm font-italic'> and </div>
          <Link
            to={'https://s3.amazonaws.com/link.tribune.documents/tos.pdf'}
            target="_blank"
            className='inline text-sm font-italic mb-5 dark:text-neutral-400 text-neutral-700 cursor-pointer hover:text-neutral-900 hover:dark:text-neutral-100'>
              terms of service
          </Link>
        </div> */}
        
      </>
    );
  } else if (stage === 'verify') {
    render = (
      <>
        <div className='text-2xl font-bold mb-1 text-center mb-2'>Verify Phone</div>
        <div className='leading-6 text-center mb-10'>
          A 6 digit code has been sent to your phone. Verify the code.
        </div>
        <PhoneVerify onVerified={onPhoneVerified} phoneNumber={phone} />
      </>
    );
  }

  

  return (
    <Modal 
        open={props.showModal}
        onClose={() => props.setShowModal(false)}
        contentLabel="Minimal Modal Example"
        showCloseIcon={true}
        blockScroll={true}
        classNames={{
          // sm:top-[0%] md:top-[4%]
          modal: `inset-x-0 mx-auto !p-0  border-[0.5px] border-neutral-700 dark:border-neutral-800
            bg-light-backdrop dark:bg-dark-backdrop rounded-lg top-[0rem] transform
            border-neutral-400 text-neutral-800 dark:text-neutral-300 w-screen sm:w-4/5 md:max-w-[30rem]`,
          overlay: `bg-neutral-800 bg-opacity-80`,
        }}
    >
      <div className='p-4 flex flex-col'>
        {render}
      </div>
    </Modal>
  );
}

SubscribeToTextModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default SubscribeToTextModal;
