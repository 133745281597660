import { React, useState, useEffect, useContext, createContext } from 'react'
import { Outlet, useParams, useLocation } from 'react-router-dom';
import { getTribuneByHandle } from '../model/tribunes.js';
import { profilePictureUrl } from '../utilities/general';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import twitter from 'twitter-text';

import { WorldContext } from '../routes/Root';

const TribuneContext = createContext('tribune');

function Tribune(props) {
  const [tribune, setTribune] = useState();
  const [membershipTier, setMembershipTier] = useState('');
  async function fetchTribune(tribuneHandle) {
    const tribune = await getTribuneByHandle(tribuneHandle);
    setWorldImage(profilePictureUrl(tribune.id));
    setWorldText(tribune.display_name);
    setTribune(tribune);
  }
  const { tribuneHandle } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { setWorldImage, setWorldText } = useContext(WorldContext);

  const hashtagClass = `font-semibold text-sky-500 hover:text-sky-600
      dark:text-sky-600 dark:hover:text-sky-500`;
  const usernameClass = `font-semibold text-sky-500 hover:text-sky-600
      dark:text-sky-600 dark:hover:text-sky-500`;
  const cashtagClass = `font-semibold text-sky-500 hover:text-sky-600
      dark:text-sky-600 dark:hover:text-sky-500`;
  const urlClass = `font-semibold text-sky-500 hover:text-sky-600
      dark:text-sky-600 dark:hover:text-sky-500`;

  useEffect(() => {
    if (!tribune) {
      if (location && location.state) {
        setTribune(location.state.tribune);
      } else {
        fetchTribune(tribuneHandle);
      }
      // 
    } else {
      // if (!location.pathname.split('/').includes('forum')) {
      //   navigate(location.pathname + '/forum');
      // }
      // setWorldImage(profilePictureUrl(tribune.id));
      // setWorldText(tribune.display_name + ' Tribune');
    }
  }, [location, tribune]);

  return (
    <TribuneContext.Provider value={{tribune, membershipTier, setMembershipTier}}>
      <div>
        {/* {tribune &&
        <div className="flex justify-center bg-light-backdrop border-neutral-300 dark:bg-dark-backdrop
            border-b dark:border-neutral-800 py-1">
          <div className="flex items-center">
            <img 
                className="mr-1.5 h-[2.5rem] w-[2.5rem] rounded-full
                    border-2 border-neutral-600 dark:border-0"
                src={profilePictureUrl(tribune.id)} alt="" />
            <div className="text-2xl font-bold tracking-tight leading-tighter text-gray-900 dark:text-neutral-300">{tribune.display_name}</div>
            <div className="flex flex-col flex-grow justify-center pl-3">
              <div className='flex tracking-tighter'>
                
                
                <span className="text-lg text-neutral-600 dark:text-neutral-400">@{tribune.twitter_handle}</span>
                <Link to={'https://twitter.com/' + tribune.twitter_handle} className="inline">
                  <span className='hidden lg:block text-neutral-500 text-md'>@{tribune.twitter_handle + ' • '}</span>
                </Link>
                <Link 
                    to={'https://twitter.com/intent/follow?screen_name=' + tribune.twitter_handle} 
                    className='hidden lg:inline ml-1 font-semibold text-md dark:text-sky-600 text-sky-500 dark:hover:text-sky-500 hover:text-sky-600'>
                  <span>Follow on X</span>
                </Link>

              </div>
              <div className="leading-tighter text-lg dark:text-neutral-300" dangerouslySetInnerHTML={{ __html: twitter.autoLink(tribune.twitter_bio, {hashtagClass, usernameClass, cashtagClass, urlClass}) }} >
              </div>
            </div>
            <div className="flex items-center mr-9">
              <div className="text-center px-4 py-1.5 text-md hover:cursor-pointer rounded-2xl
                  font-semibold border-2 border-primary hover:bg-light-raised dark:border-1 dark:border-primary
                  dark:bg-dark-backdrop dark:hover:bg-dark-raised dark:text-neutral-300 bg-light-backdrop
                  text-neutral-800">
                Join
              </div>
            </div>
          </div>
        </div>
        } */}
        <div className="flex justify-center">
          <Outlet />
        </div>
      </div>
    </TribuneContext.Provider>
  )
}

export {
  TribuneContext
}

export default Tribune;
