import React, { useState, useEffect, useContext } from 'react';

const policy = (
  <div className='dark:text-neutral-100'>
    <ol>
      <li><b>Introduction</b>

        <p>
          Welcome to Tribune! Tribune, Inc. (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) is dedicated to protecting your privacy.
          This Privacy Policy explains how we collect, use, and secure your personal information when you use our
          mobile application, Tribune.
        </p>
      </li>
      <li><b>Information We Collect</b>

        <p>
          We collect the following types of information:
        </p>

        <p>
          Personal Information: When you use Tribune, we may collect personal information such as your name, phone number, and email address. We collect this information when you provide it to us, such as when you create an account or contact us for support.
        </p>

        <p>
          Profile Data: We may collect profile data provided by you.
        </p>

        <p>
          X Profile Data: If you choose to connect with your X account, we may collect certain information from your X profile as permitted by X&apos;s policies.
        </p>

        <p>
          Facebook Profile Data: If you choose to connect with your Facebook account, we may collect certain information from your Facebook profile as permitted by Facebook&apos;s policies.
        </p>

        <p>
          Device Information: We may collect non-personal information and device information, including device ID, IP address, and country determined by IP address, for security, service improvement, and determining sweepstakes eligibility.
        </p>
      </li>
      <li><b>How We Use Your Information</b>

      <p>
        We use the collected information for the following purposes:
      </p>

      <ul>
        <li>To create and manage your Tribune account.</li>
        <li>To personalize your content and experience within our app.</li>
        <li>To communicate with you and provide customer support.</li>
        <li>For marketing and promotional purposes</li>
        <li>For security, fraud prevention, and to protect our interests.</li>
      </ul>

      </li>
      <li><b>Data Sharing</b>

      <p>
        Your IP address may be sent to the Maxmind GeoIP service using the fast-geoip NPM package to determine your country
      </p>
      </li>
      <li><b>Data Retention</b>

      <p>
        We retain your data until you request its deletion. You can request data deletion by emailing
        us at <a href="mailto:contact@tribune.link">contact@tribune.link</a>.
      </p>
      </li>
      <li><b>Sweepstakes</b>

      <p>
      By participating in our sweepstakes, you acknowledge and agree that your handle or identity may be publicly disclosed in winner announcements or posts on social media platforms. This disclosure is an integral part of the sweepstakes promotion
      </p>
      <p>
      Data about number of entries in giveaways as well as items used, and user stats or metrics collected via use of Tribune and other social media platforms, may be publicly disclosed.
      </p>
      </li>

      <li><b>User Rights</b>

      <p>
        You can access and update your personal information by emailing us at <a href="mailto:contact@tribune.link">contact@tribune.link</a>. You can withdraw your consent to data collection by discontinuing the use of our app.
      </p>
      </li>
      <li><b>Data Security</b>

      <p>
        We employ reasonable security measures to safeguard your data from unauthorized access or breaches. We use
        SSL encryption for data transmission.
      </p>
      </li>
      <li><b>Cookies and Tracking</b>

      <p>
        We use a essential session cookie to keep track of your session within our app. You can manage your cookie preferences
        within your browser settings.
      </p>
      </li>
      <li><b>Children&apos;s Privacy</b>

      <p>
        Tribune is not intended for children under the age of 13, and we do not knowingly collect personal
        information from children. If you believe a child has provided us with personal information, please contact us,
        and we will delete it.
      </p>
      </li>
      <li><b>Location Data</b>

      <p>
        We do not collect or use location data.
      </p>
      </li>
      <li><b>Policy Changes</b>

      <p>
        We will notify users of any significant changes to this Privacy Policy via email. To review the most
        recent version of the policy, please visit <a href="https://tribune.link/privacy">https://tribune.link/privacy</a>.
      </p>
      </li>
      <li><b>User Consent</b>

      <p>
        By signing in to Tribune using your third-party X or Facebook account, you grant consent to the collection
        and use of the data outlined in this Privacy Policy.
      </p>
      </li>
      <li><b>Contact Us</b>

      <p>
        If you have any questions or concerns about our Privacy Policy or data practices, please contact us at:
      </p>

      <p>Tribune, Inc.</p>
      <p>Email: <a href="mailto:contact@tribune.link">contact@tribune.link</a></p>
      </li>
    </ol>
  </div>
);

function Privacy() {
  return policy;
}

export default Privacy;
