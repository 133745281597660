import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Menu } from '@headlessui/react';
import { profilePictureUrl } from '../utilities/general';
import { formatTimeDifference } from '../utilities/formatting.js';
import PostMenu from '../components/PostMenu';

import { UserContext } from '../routes/Root';

function TribunePostCardHeading(props) {
  return (
    <div className="flex justify-between items-start pt-3">
      <div className="flex gap-x-2 items-center">
          <img src={profilePictureUrl(props.authorId)} alt="" className="h-12 w-12 rounded-full bg-gray-50" />
        <div className="flex flex-col leading-4 text-neutral-800 dark:text-neutral-300">
            <span className="font-semibold text-big mb-0.5">
              {props.authorDisplayName}
            </span>
            <div className='flex gap-x-1'>
              <span className='text-neutral-500 text-sm'>@{props.authorHandle}</span>
            </div>
        </div>
      </div>
      <div className=''>
        <img className='w-7 h-7 opacity-60' src='/images/icon.png'/>
      </div>
      {/* {user && user.id === props.authorId &&
        <PostMenu id={props.id} onDeleteClick={props.onDeleteClick} />
      } */}
    </div>
  );
}

TribunePostCardHeading.propTypes = {
  id: PropTypes.number.isRequired,
  authorId: PropTypes.number,
  authorHandle: PropTypes.string,
  authorDisplayName: PropTypes.string,
  pictureUrl: PropTypes.string,
  timestamp: PropTypes.string.isRequired,
  tribuneHandle: PropTypes.string,
  outsideTribune: PropTypes.bool,
  onDeleteClick: PropTypes.func,
};

TribunePostCardHeading.defaultProps = {
  outsideTribune: false
};

export default TribunePostCardHeading;
