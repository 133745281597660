
import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { insertTextNotificationSignUp } from '../model/tribunes';

import GiveawayAbout from '../components/GiveawayAbout';
import ItemCard from '../components/ItemCard';
import ProximityLeaderboard from '../components/ProximityLeaderboard';
import SubscribeToTextButton from '../components/SubscribeToTextButton';

function GiveawayEntered(props) {
  
  async function onTextSignUp() {
    await insertTextNotificationSignUp(props.giveaway.id);
    props.updateGiveaway();
  }

  if (!props.giveaway) { return <></> }

  return (
    <div className='dark:text-white'>
      <div className='text-lg mt-3 font-bold'>✅ Entered with {props.userEntries > 1 ? props.userEntries + ' entries!' : 1 + ' entry!'}</div>
      
      <ProximityLeaderboard ranking={props.giveaway.ranking}/>


      {props.giveaway.sms_bonus_eligible && (
        <>
          <div className='text-lg mt-5 mb-2 dark:text-white font-bold'>🚀 Boost your chances</div>
          <SubscribeToTextButton onComplete={onTextSignUp} />
        </>
      )}

      {props.loot && props.loot.length > 0 && 
      <>
        <div className='text-lg mt-5 mb-2 dark:text-white font-bold'>🎁 Loot added to inventory</div>
        <div className='grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-1.5'>
          {props.loot.map((item, index) => (
            <ItemCard
            key={index}
            index={index}
            item={item}
            addToSockets={null}
            isSelected={false}
          />
          ))}
        </div>
      </>
      }
    </div>
  )
}

GiveawayEntered.propTypes = {
  giveaway: PropTypes.object,
  updateGiveaway: PropTypes.func,
  loot: PropTypes.array,
  userEntries: PropTypes.string
}

export default GiveawayEntered;