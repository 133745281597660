


import { React, Component } from 'react'
import { Link } from "react-router-dom";
import TribuneDiscoveryCard from '../components/TribuneDiscoveryCard.js';

class MyTribunes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signedIn: false,
      myTribunes: []
    };
  }



  render() {
    return (
      <div className="flex flex-col items-center">
        {/* {this.state.myTribunes.map((tribune, index) => ( */}
          {/* <p key={index}>asdf</p>
          <TribuneDiscoveryCard key={index} tribune={tribune} /> */}
        {/* ))} */}
      </div>
    );
  }
}

export default MyTribunes;
