import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import { uploadFileToS3 } from '../utilities/S3FileUpload';

import { UserContext, LightingContext } from '../routes/Root';
import { GiveawayContext } from './Giveaway';

import GiveawayPostModal from '../components/GiveawayPostModal.js';

import LIGHTING from '../scenes/lighting';

import { getPostsByTribuneHandleForUser, createPost, insertPostImage } from '../model/posts.js';

function CreateGiveawayPost() {
  const { user } = useContext(UserContext);
  const { giveaway } = useContext(GiveawayContext);
  const { lightingTheme } = useContext(LightingContext);
  const [imageExtension, setImageExtension] = useState();
  const [imageFile, setImageFile] = useState();
  const [showPostModal, setShowPostModal] = useState(false);

  const textareaRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);

  function toggleShowPostModal() {
    if (showPostModal) {
      setShowPostModal(false);
    } else {
      setShowPostModal(true);
    }
  }

  async function handlePost(event, type) {
    event.preventDefault();
    const text = textareaRef.current.value;
    const tempPost = {
      'body': text,
      'author_id': user.id,
      'author_handle': user.handle,
      'author_display_name': user.display_name,
      'created_at': new Date().toISOString(),
    }
    if (imagePreview) {
      tempPost.media_url = imagePreview;
    }

    try {
      let date = new Date();
      date.setDate(date.getDate() + 1); // tomorrow
      date.setHours(8, 0, 0, 0); // 8am
      date.setHours(date.getHours() - 5); // est

      // Ensure the date is in the correct format for MySQL DATETIME (YYYY-MM-DD HH:MM:SS)
      const scheduledDate = date.toISOString().slice(0, 19).replace('T', ' '); 
      const resp = await createPost(
          { 
            'title': '',
            'body': text,
            'authorId': user.id,
            'scheduledDate': scheduledDate
          },
      );
      const newPost = resp.post;
      
      // Upload to S3
      let imageUrl, imageId;
      if (imagePreview) {
        imageId = uuidv4();
        const result = await uploadFileToS3(imageId, imageFile, imageExtension);
        imageUrl = result.url.split("?")[0];
        insertPostImage(imageId, resp.result.insertId, imageUrl, imageExtension);
        newPost.media_url = imagePreview;
      }
    } catch(error) {
      // setDialogText('You have reached the posting limit.\n Limits:\n* 2 times per 10 minutes\n* 5 times per hours\n* 20 times per 24 hours')
      // setIsDialogOpen(true);
      console.error('Error: ', error.message, error);
      return;
    }
  }

  if (!giveaway) { return <></>}

  return (
    <div className='flex flex-col w-full'>
      <div className='text-lg'>{giveaway.title}</div>
      <div>{giveaway.description}</div>

      <div 
        className='p-2 bg-primary font-medium cursor-pointer'
        onClick={() => setShowPostModal(true)}
      >
        Create Post
      </div>
      <GiveawayPostModal
          isReply={false}
          xUsername={user.handle}
          xDisplayName={user.display_name}
          showModal={showPostModal}
          toggleShowModal={toggleShowPostModal}
          handlePost={handlePost}
          textareaRef={textareaRef}
          setImageFile={setImageFile}
          setImageExtension={setImageExtension}
          imagePreview={imagePreview}
          setImagePreview={setImagePreview}
      />
    </div>
  );
}

CreateGiveawayPost.propTypes = {
}

export default CreateGiveawayPost;
