import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import twitter from 'twitter-text';

import { formatTextWithEntities } from '../utilities/formatting';

function TribunePostCardContent(props) {
  const hashtagClass = `font-semibold text-sky-500 hover:text-sky-600
      dark:text-sky-600 dark:hover:text-sky-500`;
  const usernameClass = `font-semibold text-sky-500 hover:text-sky-600
      dark:text-sky-600 dark:hover:text-sky-500`;
  const cashtagClass = `font-semibold text-sky-500 hover:text-sky-600
      dark:text-sky-600 dark:hover:text-sky-500`;
  const urlClass = `font-semibold text-sky-500 hover:text-sky-600
      dark:text-sky-600 dark:hover:text-sky-500`;
  return (
    <div className='overflow-hidden max-h-[45rem] rounded-md'>
      <div className="pb-1 pt-3 text-lg text-base leading-6 break-words text-neutral-800 dark:text-neutral-300 whitespace-pre-line"
          dangerouslySetInnerHTML={{ __html: twitter.autoLink(props.text, {hashtagClass, usernameClass, cashtagClass, urlClass}) }} >
      </div>
      {props.mediaUrl &&
        <img src={props.mediaUrl} className='max-h-[40rem] rounded-md mt-3' />
      }
    </div>
  );
}

TribunePostCardContent.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  mediaUrl: PropTypes.string,
  hashtag: PropTypes.string.isRequired,
};

export default TribunePostCardContent;
  