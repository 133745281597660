import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate} from "react-router-dom";
import { getTribunes } from '../model/tribunes.js';
import { isProdEnv } from '../utilities/environment'
import TribuneDiscoveryCard from '../components/TribuneDiscoveryCard.js';
import FacebookFriendsCard from '../components/FacebookFriendsCard.js';
import { UserContext } from '../routes/Root';

function Discover() {
  const { user } = useContext(UserContext);
  const [signedIn, setSignedIn] = useState(false);
  const [tribunes, setTribunes] = useState([]);
  const navigate = useNavigate();

  async function fetchTribunes() {
    const tribunes = await getTribunes();
    setTribunes(tribunes);
  }

  useEffect(() => {
    // fetchTribunes();
    // if (isProdEnv()) {
    //   navigate('/t/gaming/forum/giveaways');
    // } else {
    //   navigate('/t/dev/forum/giveaways');
    // }
  }, []);


  return (
    <>
      {/* <div className="flex bg-light-backdrop dark:bg-dark-backdrop border-b border-neutral-300
          dark:border-neutral-800 py-3 px-4 sm:px-6 lg:px-8 justify-center">
        <div className="flex-rows w-1/3">
          <div className="flex items-center">
            <h1 className="text-3xl mb-1 mr-2 font-bold text-neutral-900 dark:text-neutral-300">Tribunes</h1>
            <p className="text-lg dark:text-neutral-400"></p>
          </div>
          <p className="text-xl dark:text-neutral-300">Contribute posts to your community</p>
        </div>
      </div>
      <div className="flex w-full justify-center mt-3">
        <div className="w-[22rem] hidden sm:block"></div>
        <div className="w-[100%] mx-8">
          <div className='grid grid-cols-1 gap-3'>
            {tribunes && tribunes.map((tribune, index) => (
              !(isProdEnv() && tribune.handle === 'dev') && (
                <TribuneDiscoveryCard key={index} tribune={tribune} />
              )
            ))}
          </div>
        </div>
        <div className="w-[22rem] hidden sm:block">
          {user && user.facebookFriends && <FacebookFriendsCard friends={user.facebookFriends}/> }
        </div>
      </div> */}
      
    </>
  );
}

export default Discover;