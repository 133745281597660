import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Menu } from '@headlessui/react';

const playerTypeStyles = {
  killer: 'dark:bg-red-700 bg-red-400',
  achiever: 'dark:bg-amber-600 bg-yellow-400',
  explorer: 'dark:bg-sky-800 bg-sky-400',
  socializer: 'dark:bg-purple-900 bg-purple-400'
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function PlayerAction(props) {
  return (
    <Menu.Item key={props.action.name} onClick={props.action.onClick}>
      {({ active }) => (
        <Link 
          key={props.action.name}
          to={props.action.route}
          className={classNames(
            active && props.action.playerType ? playerTypeStyles[props.action.playerType] : '',
            'flex justify-start items-center block px-3 py-2 text-md text-neutral-800 dark:text-neutral-200 font-bold hover:cursor-pointer'
          )} 
          // justify-end
        >
          <span>{props.action.name}</span>
          {/* <img src={props.action.image} className='inline w-7 h-7 ml-2 bg-neutral-100 rounded-lg p-[3px] ' /> */}
        </Link>
      )}
    </Menu.Item>
  );
}

PlayerAction.propTypes = {
  action: PropTypes.object.isRequired,
};

export default PlayerAction;
