import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

function ConnectPhoneButton(props) {
  const [animationClass, setAnimationClass] = useState('');
  const [borderClass, setBorderClass] = useState('border-neutral-200');
  const [connectText, setConnectText] = useState('Add');
  let buttonClass = `flex flex-col justify-center h-[11rem] w-[170px] border-2 dark:border-neutral-400
      border-neutral-500 hover:border-primary dark:bg-neutral-900 bg-neutral-50 rounded-lg items-center
      justify-center p-4 cursor-pointer dark:text-neutral-200 text-neutral-900 ${borderClass} ${animationClass}`;

  return (
    <div 
        className={buttonClass}
        onClick={() => {props.setStage("INPUT")}}>
      <div className='rounded-full mb-2 text-lg font-semibold'>Phone</div>
      <img src='/images/phone.png' className='w-16 h-16 mb-1' />
      <p className="flex-none mx-3 px-4 py-1.5 text-md text-center font-semibold rounded-lg">
        {connectText}
      </p>
    </div>
  )
}

ConnectPhoneButton.propTypes = {
  setStage: PropTypes.func.isRequired,
  
  // onClick: PropTypes.func.isRequired,
  // facebookConnected: PropTypes.bool.isRequired,
  // setFacebookConnected: PropTypes.func.isRequired,
  // setLoggingInUser: PropTypes.func.isRequired,
}

export default ConnectPhoneButton;
