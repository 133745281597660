import { React, Fragment, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import twitter from 'twitter-text';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
const customModalStyle = {
  background: '#b2dbbf',
  top: '20%',
  maxWidth: '500px',
  width: '100%',
};

function RejectModal(props) {
  const [text, setText] = useState('');
  // const [placeholder, setPlaceholder] = useState(null);
  const [heading, setHeading] = useState('Reject post');

  const handleTextChange = (event) => {
    let inputText = event.target.value;
    setText(inputText);

    const target = props.textareaRef.current;
    target.style.height = 'auto'; // Reset height to allow shrinking
    target.style.height = `${target.scrollHeight}px`; // Adjust height based on scroll height
  };

  function handleClose() {
    props.toggleShowModal();
    // As the modal fades, let the content remain so we don't resize, etc. Looks better
    setTimeout(() => {
      setText('');
    }, 300);
  }

  function handleClickReject(event) {
    if (text === '') {
      return;
    }
    handleClose();
    setTimeout(() => {
      props.handleDecidePost(props.postId, false, text);
    }, 300);
  }

  return (
    <Modal 
        open={props.showModal}
        contentLabel="Minimal Modal Example"
        showCloseIcon={false}
        blockScroll={true}
        classNames={{
          modal: `fixed inset-x-0 top-[20%] mx-auto !p-0 border-[0.5px] border-neutral-700 dark:border-neutral-800
            bg-light-backdrop dark:bg-dark-backdrop rounded-lg transform
            border-neutral-400 text-neutral-800 dark:text-neutral-300`,
          // overlay: `bg-black bg-opacity-80`
          overlay: `bg-neutral-800 bg-opacity-80`
        }}
    >
      <div className='flex flex-col p-0'>
        <div className="flex justify-center border-b-[1px] border-neutral-300 dark:border-neutral-800 pt-1 pb-3">
          <div className="text-xl font-bold mt-2">{heading}</div>
          <img 
              className="absolute right-3 font-bold w-9 h-9 cursor-pointer
                dark:bg-neutral-800 mt-1 p-1.5 rounded-full bg-neutral-200
                dark:hover:bg-dark-backdrop hover:bg-light-backdrop"
              src='/images/cancel.png'
              onClick={handleClose} />
        </div>
      </div>
      
      <div className="flex flex-col pt-2 flex-grow max-h-[35rem] overflow-y-auto show-scrollbar">
        
          <div className='flex grow mb-2 px-2'>
          <textarea
              name="postText"
              placeholder='Give a reason for post rejection'
              ref={props.textareaRef}
              value={text}
              autoFocus
              // rows={rows}
              onChange={handleTextChange}
              maxLength="280"
              className="py-1 h-[67px] grow resize-none px-3 appearance-none border-none text-lg
                   p-4 dark:placeholder-neutral-500 text-lg placeholder:text-2xl
                  dark:text-neutral-200 dark:bg-dark-backdrop bg-light-backdrop">    
          </textarea>
        </div>
          
        <div className='flex border-t-[3px] dark:border-dark-backdrop border-light-backdrop'>
          <button 
              onClick={handleClickReject}
              className="grow px-4 py-1.5 text-md text-center font-semibold rounded-b
              text-neutral-100 bg-red-600 dark:bg-red-700 hover:bg-red-700 dark:hover:bg-red-600">
            Reject
          </button>
        </div>
      </div>
    </Modal>
  );
}

RejectModal.propTypes = {
  showModal: PropTypes.bool,
  toggleShowModal: PropTypes.func.isRequired,
  textareaRef: PropTypes.object.isRequired,
  postId: PropTypes.number.isRequired,
  handleDecidePost: PropTypes.func.isRequired,
}

export default RejectModal;
