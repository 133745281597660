import axios from 'axios';
import { getAPIDomain } from '../utilities/environment';

const ROOT_URL = 'https://' + getAPIDomain();

export async function getUsers() {
  const url = ROOT_URL + '/users';
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
      'Content-Type': 'application/json'
      },
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function getUserByFacebookId(facebookId) {
  const url = ROOT_URL + '/users/facebook/' + facebookId;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
      'Content-Type': 'application/json'
      }
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
      console.log("error: " + err);
  }
}

export async function updateUserPhoneById(phone) {
  const url = ROOT_URL + '/users/phone';
  const user = {
      phone
  }
  try {
    const { data: responseData } = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user),
      credentials: 'include'
    });
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}
