
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getGiveawayPhotoS3Bucket } from '../utilities/environment';

function GiveawayPhoto(props) {
  const [imageLoaded, setImageLoaded] = useState(true);

  return (
    <img
      className={`w-full mb-2 ${imageLoaded ? 'block' : 'hidden'}`}
      src={'https://s3.amazonaws.com/' + getGiveawayPhotoS3Bucket() + '/' + props.giveawayId + '.png'}
      onLoad={() => setImageLoaded(true)} // Set imageLoaded to true when the image loads
    />
  )
}

GiveawayPhoto.propTypes = {
  giveawayId: PropTypes.number
}

export default GiveawayPhoto
