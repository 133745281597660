import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';
import PostPoints from './PostPoints';
import { TribuneContext } from '../routes/Tribune';
import { UserContext } from '../routes/Root';
import { goToTwitterAuth } from '../utilities/twitter';

function TribunePostCardSideBar(props) {
  const { membershipTier } = useContext(TribuneContext);
  const { user } = useContext(UserContext);
  const [initialPoints, setInitialPoints] = useState(props.points);

  function isTimestampWithin72Hours(timestamp) {
    const currentTimestamp = new Date();
    const providedTimestamp = new Date(timestamp);
  
    // Calculate the time difference in milliseconds
    const timeDifferenceMs = currentTimestamp - providedTimestamp;
  
    // Convert milliseconds to hours
    const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60);
  
    return timeDifferenceHours < 72;
  }

  function onClick() {
    if (user) {
      props.onVoteClick(voterPoints);
    } else {
      goToTwitterAuth('user');
    }
  }

  let votingDisabled = true;
  let energyImg;
  if (props.hasVoted && user) {
    energyImg = (
      <div className="relative group">
        <img src='/images/fire-energy.png' className='w-[27px] h-[27px] cursor-not-allowed'/>
        <div className="hidden group-hover:block absolute top-0 left-10 w-[12rem] h-full z-10 opacity-0
            group-hover:opacity-100 text-neutral-800 bg-neutral-200 font-medium dark:bg-neutral-700 rounded-lg bg-opacity-100 dark:text-white text-center line-height-40">
          You have already voted
        </div>
      </div>
    );
  } else if (!isTimestampWithin72Hours(props.createdAt) && user) {
    energyImg = (
      <div className="relative group">
        <img src='/images/fire-energy.png' className='w-[27px] h-[27px] opacity-50 cursor-not-allowed'/>
        <div className="hidden group-hover:block absolute group-hover:absolute w-[10rem] top-0 left-10 h-1/2 z-10 opacity-0
            group-hover:opacity-100 text-neutral-800 bg-neutral-200 font-medium dark:bg-neutral-700 rounded-lg bg-opacity-100 dark:text-white text-center line-height-40">
          Voting time expired
        </div>
      </div>
    );
  } else {
    energyImg = <img src='/images/fire-energy.png' onClick={onClick} className='w-[27px] h-[27px] opacity-50 hover:opacity-100 cursor-pointer'/>;
    votingDisabled = false;
  }

  let voterPoints;
  switch (membershipTier) {
    case 'Newcomer':
      voterPoints = 1;
      break;
    case 'Bronze':
      voterPoints = 5;
      break
    case 'Silver':
      voterPoints = 8;
      break
    case 'Gold':
      voterPoints = 13;
      break
    case 'Platinum':
      voterPoints = 21;
      break
    case 'Diamond':
      voterPoints = 34;
      break
    case 'Onyx':
      voterPoints = 55;
      break
  }
  
  return (
    <div className="hidden md:flex flex-col items-center rounded-l bg-light-backdrop dark:bg-dark-backdrop pt-3 mx-3 sm:ml-0 lg:mr-1">
      <div className='mb-2'>
        <PostPoints from={initialPoints} to={props.points} />
      </div>
      <ProgressBar votingExpired={!isTimestampWithin72Hours(props.createdAt)} progressPercentage={props.voteThresholdPercent} />
      {energyImg}
    </div>
  );
}

TribunePostCardSideBar.propTypes = {
  points: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  hasVoted: PropTypes.bool.isRequired,
  postId: PropTypes.number.isRequired,
  authorId: PropTypes.number.isRequired,
  onVoteClick: PropTypes.func.isRequired,
  voteThresholdPercent: PropTypes.number.isRequired,
};

export default TribunePostCardSideBar;
