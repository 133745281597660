import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

function PostCardContent(props) {
  if (props.reply) {
    return (
      <Link to={'../post/' + props.id} className="py-1.5 text-lg text-base leading-6 break-words text-neutral-800 dark:text-neutral-100">
        {props.text}
      </Link>
    );
  } else {
    return (
      <p className="pb-3 pt-3 text-lg text-base leading-6 break-words text-neutral-800 dark:text-neutral-100 whitespace-pre-line">
        {props.text}
      </p>
    );
  }
}

PostCardContent.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  reply: PropTypes.bool
};

export default PostCardContent;
  