import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { verifyCode } from '../model/phone';
import { updateUserPhoneById } from '../model/users';
import { SignUpContext } from '../components/SignUpModal';
import { UserContext } from '../routes/Root';


function ConnectPhoneVerify(props) {
  const { setPhoneConnected } = useContext(SignUpContext);
  const [code, setCode] = useState(null);
  const codeInputRef = useRef(null);
  const { setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const verifyResult = await verifyCode(props.phoneNumber, code);
    if (verifyResult.status === 'approved') {
      const userResult = await updateUserPhoneById(props.phoneNumber);
      setUser(userResult);
      localStorage.setItem('user', JSON.stringify(userResult));
      setPhoneConnected(true);
    } else {
      if (codeInputRef.current) codeInputRef.current.value = '';
      alert('Incorrect code');
    }
    setLoading(false);
  }

  function newClicky() {
    props.setStage('INPUT');
  }

  useEffect(() => {
    props.setActionImage('/images/back.png');
    props.setActionOnClick(() => newClicky);
  }, [])

  return (
    <div className='flex flex-col justify-center items-center gap-10'>
      <form onSubmit={(event) => handleSubmit(event)}
          className='flex flex-col'>
        <div className='flex justify-center'>
          {loading ? 
          <div className="w-24 h-24 border-t-4 border-green-500 rounded-full animate-spin-slow"></div>
          : (
          <div className='flex flex-col justify-center'>
          <input
              type='tel'
              ref={codeInputRef}
              onChange={(event) => setCode(event.target.value)} 
              placeholder='000000'
              className='text-center text-neutral-800 rounded-t-lg border-0 w-[155px]'
              maxLength="6" />
          <input type='submit' value='Confirm' className='cursor-pointer bg-primary hover:bg-green-500 text-neutral-50 p-1.5 rounded-b-lg font-semibold' />
          </div>
          )}
        </div>
        
        
      </form>
    </div>
  )
}

ConnectPhoneVerify.propTypes = {
  setPhoneConnected: PropTypes.func.isRequired,
  setStage: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  loggingInUserId: PropTypes.number.isRequired,
  setActionImage: PropTypes.func.isRequired,
  setActionOnClick: PropTypes.func.isRequired,
}

export default ConnectPhoneVerify;
