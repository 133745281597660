import { React, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Menu } from '@headlessui/react';
import { profilePictureUrl } from '../utilities/general';

import LIGHTING from '../scenes/lighting';
import { PlayerContext, UserContext, SceneContext, LightingContext } from '../routes/Root';

function ItemCard(props) {
  const borderColor = props.isSelected ? 'border-primary' : 'border-neutral-300 dark:border-neutral-700';

  const rarityColor = {
    'Common': 'text-green-500 dark:text-primary',
    'Uncommon': 'text-cyan-600 dark:text-cyan-400',
    'Rare': 'text-violet-800 dark:text-violet-400',
    'Ultra-rare': 'text-red-700 dark:text-red-500',
    'Forged': 'text-amber-600 dark:text-amber-400',
    'Ability': 'text-neutral-800 dark:text-netrual-200'
  };

  const selectableClass = props.addToSockets !== null ? 'hover:border-primary hover:dark:border-primary cursor-pointer' : '';
  let borderClass = borderColor;
  if (props.item.rarity === 'Forged' && props.addToSockets === null) {
    borderClass = 'border-amber-600 dark:border-amber-400'
  } else if (props.item?.is_bonus) {
    borderClass = 'border-cyan-600 dark:border-cyan-400'
  }

  const bonusInsignia = props.item.is_bonus ?
    <img
        src='/images/item-9.png'
        className='absolute border-2 dark:border-neutral-00 z-10 top-[-20px] right-[-15px] rounded-full h-[3.5rem] w-[3.5rem]' 
    /> : <></>;

  return (
    <div
        onClick={() => {props.addToSockets(props.index, props.item)} }
        className={`relative rounded-md border-[3px] w-[22rem] h-[10rem] px-3 ${borderClass} bg-white dark:bg-neutral-800 ${selectableClass}`}>
      {bonusInsignia}

      <div className='text-lg font-bold text-neutral-800 dark:text-neutral-200 py-1'>{props.item.name}</div>
      <div className='flex text-neutral-800 dark:text-neutral-200'>
        {/* <img className='rounded-lg inline w-[6rem] h-[6rem]' src={`/images/item-${props.item.id}.png`} /> */}
        <div className='flex flex-col'>
        <div className='flex'>
          <img className='rounded-lg inline w-[5rem] h-[5rem]' src={`/images/item-${props.item.id}.png`} />
          <div className='dark:text-neutral-200 font-medium mx-3 grow'>
            {props.item.description}
          </div>
        </div>
        <div className='flex justify-between mt-2'>
          <div className={`font-bold text-right dark:text-neutral-200`}>
            {props.item.is_consumable ? !props.item.chargable ? 'Can be used once' : `${props.item.charges_remaining} charge${props.item.charges_remaining > 1 ? 's' : ''} remaining` : 'Unlimited uses'}
          </div>
          <div className={`font-bold text-right ${rarityColor[props.item.rarity]}`}>
            {props.item.rarity} 
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

ItemCard.propTypes = {
  item: PropTypes.object,
  addToSockets: PropTypes.func,
  isSelected: PropTypes.bool,
  index: PropTypes.number,
};

export default ItemCard;
