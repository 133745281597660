import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { formatTimeDifference } from '../utilities/formatting.js';
import { decidePost } from '../model/posts.js';
import RejectModal from '../components/RejectModal.js';
import { UserContext } from '../routes/Root';
import { publishApprovedPostToX } from '../model/posts.js';

function PostModerationCardBottom(props) {
  const { user } = useContext(UserContext);
  const [clickedReject, setClickedReject] = useState(false);
  const [hoverApprove, setHoverApprove] = useState(false);
  const [hoverReject, setHoverReject] = useState(false);
  const [approveImageClass, setApproveImageClass] = useState();
  const [approveTextClass, setApproveTextClass] = useState();
  const [rejectImageClass, setRejectImageClass] = useState();
  const [rejectTextClass, setRejectTextClass] = useState();
  const [showModal, setShowModal] = useState();
  const textareaRef = useRef(null);

  function toggleShowModal() {
    if (showModal) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  }

  async function handleDecidePost(post_id, approve, reason=null) {
    try {
      await decidePost(post_id, approve, reason);
      // await publishApprovedPostToX()
    } catch(error) {
      console.error("Error deleting post\n", error);
    }
    const newPosts = props.posts.filter(item => item.id !== post_id);
    props.setPosts(newPosts);
  }

  useEffect(() => {
    if (hoverApprove) {
      setApproveImageClass('inline h-5 w-5 mb-1 opacity-100');
      setApproveTextClass('pl-2 text-neutral-900 dark:text-neutral-200');
    } else {
      setApproveImageClass('inline h-5 w-5 mb-1 opacity-80');
      setApproveTextClass('pl-2 text-neutral-600 dark:text-neutral-400');
    }
  }, [hoverApprove]);

  useEffect(() => {
    if (hoverReject) {
      setRejectImageClass('inline h-5 w-5 mb-1 opacity-100');
      setRejectTextClass('pl-2 text-neutral-900 dark:text-neutral-200');
    } else {
      setRejectImageClass('inline h-5 w-5 mb-1 opacity-80');
      setRejectTextClass('pl-2 text-neutral-600 dark:text-neutral-400');
    }
  }, [hoverReject]);

  return (
    <>
    <RejectModal
          showModal={showModal}
          toggleShowModal={toggleShowModal}
          textareaRef={textareaRef}
          postId={props.id}
          handleDecidePost={handleDecidePost}
      />
    <div className="flex flex-col font-semibold text-[15px] pt-5">
      <div className='flex grow text-sm justify-between'>
        <div className='flex gap-x-8'>
          <div
              onClick={() => handleDecidePost(props.id, true)}
              onMouseEnter={() => setHoverApprove(true)}
              onMouseLeave={() => setHoverApprove(false)}
              className='flex cursor-pointer'>
            <img src='/images/approve.png' className={approveImageClass} />
            <span className={approveTextClass}>
              Post to X
            </span>
          </div>
          <div
              onMouseEnter={() => setHoverReject(true)}
              onMouseLeave={() => setHoverReject(false)}
              onClick={() => {toggleShowModal()}}
              className='flex cursor-pointer'>
            <img src='/images/reject.png' className={rejectImageClass} />
            <span className={rejectTextClass}>Reject</span>
          </div>
        </div>

        <div className='flex'>
          <span className={`text-neutral-700 dark:text-neutral-300`}>{formatTimeDifference(props.timestamp)}</span>
        </div>
      </div>
      {clickedReject && 
      <textarea
        name="postText"
        placeholder={'Give a reason for rejection'}
        ref={textareaRef}
        autoFocus
        // rows={rows}
        maxLength="500"
        className="py-1 h-[135px] grow resize-none px-3 appearance-none border-none text-lg
            p-4 dark:placeholder-neutral-500 text-lg placeholder:text-2xl
            dark:text-neutral-200 dark:bg-dark-backdrop bg-light-backdrop">    
  </textarea>
        // <textarea
        //   placeholder='Give a reason for rejection'
        // >
        // </textarea>
      }
      
      
    </div>
    </>
  );
}

PostModerationCardBottom.propTypes = {
  id: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  posts: PropTypes.array.isRequired,
  setPosts: PropTypes.func.isRequired,
};

export default PostModerationCardBottom;
