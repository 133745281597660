import { React, useState, useEffect, useContext, createContext } from 'react'
import { Outlet, useParams, useLocation } from 'react-router-dom';
import { getGiveawayById } from '../model/tribunes.js';
import { profilePictureUrl } from '../utilities/general';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import twitter from 'twitter-text';
import { UserContext } from './Root';

import { WorldContext } from '../routes/Root';

const GiveawayContext = createContext('giveaway');

function Giveaway(props) {
  const { giveawayId } = useParams();
  const [giveaway, setGiveaway] = useState();
  const { user } = useContext(UserContext);

  async function updateGiveaway() {
    const giveaway = await getGiveawayById(giveawayId, user ? true : false);
    setGiveaway(giveaway);
  }

  useEffect(() => {
    updateGiveaway();
  }, [])

  return (
    <GiveawayContext.Provider value={{ giveaway, updateGiveaway }}>
        <div className="flex justify-center">
          <Outlet />
        </div>
    </GiveawayContext.Provider>
  )
}

export {
  GiveawayContext
}

export default Giveaway;
