import { getProfilePictureS3Bucket, getFacebookPictureS3Bucket } from '../utilities/environment';

export function profilePictureUrl(entityId) {
  return 'https://s3.amazonaws.com/' + getProfilePictureS3Bucket() + '/'  + entityId + '.jpg'
}

export function facebookPictureUrl(entityId) {
  return 'https://s3.amazonaws.com/' + getFacebookPictureS3Bucket() + '/'  + entityId + '.jpg'
}

export function countTrue(bool1, bool2, bool3) {
  const boolArray = [bool1, bool2, bool3];
  const trueCount = boolArray.filter((bool) => bool === true).length;
  console.log(`Number of true values: ${trueCount}`);
  return trueCount + 1;
}

export function playSound(sound) {
  const soundMuted = localStorage.getItem('soundMuted');
  if (!soundMuted || localStorage.getItem('soundMuted') === 'false') {
    sound.play();
  }
}

