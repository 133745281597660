import { React, Fragment, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
// import Modal from 'react-modal';
import { formatTextWithEntities } from '../utilities/formatting';
import twitter from 'twitter-text';
import { profilePictureUrl } from '../utilities/general';

import { LightingContext, UserContext } from '../routes/Root';
import LIGHTING from '../scenes/lighting';
import ChoosePostType from './ChoosePostType';

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
// import '../styles/customModal.css';
const customModalStyle = {
  background: '#b2dbbf',
  top: '20%',
  maxWidth: '500px',
  width: '100%',
};

// Modal.setAppElement(document.getElementById('root'));

function GiveawayPostModal(props) {
  const { user } = useContext(UserContext);
  const { lightingTheme } = useContext(LightingContext);
  const hashtagClass = `font-semibold text-green-600 dark:text-primary`;

  // const postSound = new Audio("/audio/post.wav");

  function generateStyle() {
    let modalStyle = {
      content: {
      postion: 'absolute',
      top: '20%',
      padding: '0px',
      borderRadius: '0.7rem',
    }, 
      overlay: { }
    };
    return modalStyle;
  }
  // const [style, setStyle] = useState(generateStyle);
  const [text, setText] = useState('');
  const [type, setType] = useState(null);
  const [placeholder, setPlaceholder] = useState(null);
  const [heading, setHeading] = useState('Create post');
  const [userImagePreview, setUserImagePreview] = useState(null);
  const [userDisplayNamePreview, setUserDisplayNamePreview] = useState('');
  const [userHandlePreview, setUserHandlePreview] = useState('');

  const selfPostPlaceholder = `If and when 
                the energy bar fills on your proposed post, it will be automatically posted by your X account. 
                You must include the hashtag #asdf`.replace(/\s+/g, ' ').trim();

  const tribunePostPlaceholder = `If and when 
    the energy bar fills on your proposed post, it will be posted by the X account @shanemcguffin upon approval.
    Your contribution will earn you a token for this tribune`.replace(/\s+/g, ' ').trim();

  const [tweetInfo, setTweetInfo] = useState(twitter.parseTweet(text));

  const handleTextChange = (event) => {
    let inputText = event.target.value;
    // const regex = new RegExp(`${props.hashtag}`, 'gi');
    // inputText = inputText.replace(regex, `${props.hashtag}`);
    setText(inputText);

    const target = props.textareaRef.current;
    target.style.height = 'auto'; // Reset height to allow shrinking
    target.style.height = `${target.scrollHeight}px`; // Adjust height based on scroll height
  };

  function handleClose() {
    props.toggleShowModal();
    // As the modal fades, let the content remain so we don't resize, etc. Looks better
    setTimeout(() => {
      setText('');
      setType(null);
      props.setImageFile(null);
      props.setImageExtension(null);
      props.setImagePreview(null);
    }, 300);
  }

  async function handleClickPost(event) {
    if (text === '') {
      return;
    }

    props.handlePost(event, type);
    setText('');
    setType(null);
    props.setImagePreview(null);
  }

  function handleClickBack(event) {
    setText('');
    setType(null);
  }

  useEffect(() => {
    setTweetInfo(twitter.parseTweet(text));
  }, [text]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    props.setImageFile(file);
    const reader = new FileReader();

    reader.onloadend = () => {
      props.setImagePreview(reader.result);
    };

    if (file) {
      const extension = file.name.split('.').pop();
      props.setImageExtension(extension);
      reader.readAsDataURL(file);
    }

    // Reset the input value so the same file can be selected again
    e.target.value = null;
  };

  return (
    <Modal 
        open={props.showModal}
        contentLabel="Create Giveaway Post Modal"
        showCloseIcon={false}
        onClose={() => handleClose()}
        blockScroll={true}
        classNames={{
          // sm:top-[0%] md:top-[4%]
          modal: `inset-x-0 mx-auto !p-0 border-[0.5px] border-neutral-700 dark:border-neutral-800
            bg-light-backdrop dark:bg-dark-backdrop rounded-lg transform
            border-neutral-400 text-neutral-800 dark:text-neutral-300 w-screen sm:w-4/5 md:w-3/4 lg:max-w-[40rem]`,
          overlay: `bg-neutral-800 bg-opacity-80`,
        }}
    >
      <div className='flex flex-col p-0 overflow-y-scroll'>
        <div className="flex justify-center border-b-[1px] border-neutral-300 dark:border-neutral-800 pt-1 pb-3">
        
          {type != null && <img className='absolute left-3 top-3 w-7 h-7 mb-1 cursor-pointer' src='/images/back.png' onClick={handleClickBack} />}
          <div className="text-xl font-bold mt-2">{heading}</div>
          <img 
              className="absolute right-3 font-bold w-9 h-9 cursor-pointer
                dark:bg-neutral-800 mt-1 p-1.5 rounded-full bg-neutral-200
                dark:hover:bg-dark-backdrop hover:bg-light-backdrop"
              src='/images/cancel.png'
              onClick={handleClose} />
        </div>
      <div className="flex flex-col px-2 pt-2 flex-grow">
        
          <div className='flex grow mb-2'>
          <textarea
              name="postText"
              placeholder={placeholder}
              ref={props.textareaRef}
              value={text}
              autoFocus
              // rows={rows}
              onChange={handleTextChange}
              maxLength="280"
              className="h-[12.5rem] py-1 grow resize-none appearance-none border-none text-lg
                  dark:placeholder-neutral-500 text-lg placeholder:text-lg sm:placeholder:text-2xl md:placeholder:text-2xl lg:placeholder:text-2xl xl:placeholder:text-2xl
                  dark:text-neutral-200 dark:bg-dark-backdrop bg-light-backdrop !px-1.5">    
          </textarea>
          </div>

          <div
              className='flex flex-col appearance-none resize-none border-[1px] dark:border-neutral-600 border-neutral-400
              dark:bg-dark-backdrop dark:text-neutral-200 bg-light-backdrop text-neutral-800 rounded-lg cursor-pointer'>
            <input className='hidden' id="fileInput" type="file" onChange={handleImageChange} accept="image/*" />
            <label htmlFor="fileInput" className='cursor-pointer px-2 pl-3.5 py-1.5 font-medium dark:text-neutral-300 text-neutral-800'>Add image</label>
          </div>

          <div
              className='flex flex-col appearance-none resize-none border-[1px] dark:border-neutral-600 border-neutral-400
              dark:bg-dark-backdrop dark:text-neutral-200 bg-light-backdrop text-neutral-800 my-2 rounded-lg p-3 cursor-pointer'>

            <div className='flex justify-between'>
              <div className='flex mb-3'>
                <img 
                    className='w-10 h-10 rounded-full'
                    src={profilePictureUrl(user.id)}
                />
                <div className='flex flex-col ml-1.5'>
                  <div className='leading-tight font-semibold'>{props.xDisplayName}</div>
                  <div className='leading-none'>@{props.xUsername}</div>
                </div>
              </div>
              <div className=''>
                <img className='w-7 h-7 opacity-60 mt-1' src='/images/twitter/x.png' />
              </div>
            </div>
            
            

            <div className='flex flex-col'>
            
              <div className='mb-1 break-words whitespace-pre-line'>{text}</div>
              <div className='flex text-sm font-semibold justify-end gap-x-1 mb-2'>
              {tweetInfo.weightedLength === 280 && <span className='text-red-500'>{tweetInfo.weightedLength}</span>}
              {tweetInfo.weightedLength >= 200 && tweetInfo.weightedLength < 280 &&  <span className='text-yellow-500 dark:text-yellow-400'>{tweetInfo.weightedLength}</span>}
              {tweetInfo.weightedLength < 200 && <span className='dark:text-primary text-green-500'>{tweetInfo.weightedLength + ' '}</span>}
                <span className=''> / 280</span>
              </div>
              {props.imagePreview && (
              <div className="relative">
                <img src={props.imagePreview} alt="Selected preview" className="max-h-[40rem] rounded-lg" />
                <button
                  onClick={() => {props.setImagePreview(null); props.setImageFile(null); props.setImageExtension(null)}}
                  className="absolute top-3 right-3 dark:bg-dark-backdrop bg-light-backdrop text-neutral-800 dark:text-neutral-200 font-bold px-3.5 p-2 rounded-full cursor-pointer"
                >
                  X
                </button>
              </div>)}
            </div>
          </div>
        </div>
        <div className='flex border-t-[3px] dark:border-dark-backdrop border-light-backdrop'>
          <button
              onClick={handleClickPost}
              className="grow px-4 py-1.5 text-md text-center font-semibold rounded-b
              dark:text-neutral-800 text-neutral-50 bg-primary hover:bg-green-500 dark:hover:bg-green-500">
            Post
          </button>
        </div>
      </div>
    </Modal>
  );
}

GiveawayPostModal.propTypes = {
  isReply: PropTypes.bool.isRequired,
  xUsername: PropTypes.string,
  xDisplayName: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  toggleShowModal: PropTypes.func.isRequired,
  handlePost: PropTypes.func.isRequired,
  textareaRef: PropTypes.object.isRequired,
  setImageFile: PropTypes.func.isRequired,
  setImageExtension: PropTypes.func.isRequired,
  imagePreview: PropTypes.object,
  setImagePreview: PropTypes.func.isRequired,
}

export default GiveawayPostModal;
