import { React, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Menu } from '@headlessui/react';
import { profilePictureUrl } from '../utilities/general';

import LIGHTING from '../scenes/lighting';
import { PlayerContext, UserContext, SceneContext, LightingContext } from '../routes/Root';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function PlayerButton(props) {
  const { playerText, playerActions, playerOnClick } = useContext(PlayerContext);
  const { user } = useContext(UserContext);
  const { sceneName, sceneState } = useContext(SceneContext);
  const { lightingTheme } = useContext(LightingContext);

  const [outsiderImage, setOutsiderImage] = useState(lightingTheme === LIGHTING.DARK ? '/images/unknown.png' : '/images/unknown-dark.png')

  function handleClick() {
    // event.preventDefault();
    playerOnClick && playerOnClick();
  }

  useEffect(() => {
    if (lightingTheme === LIGHTING.DARK) {
      setOutsiderImage('/images/unknown.png');
    } else {
      setOutsiderImage('/images/unknown-dark.png');
    }
  }, [lightingTheme])
  
  return (
    <Menu as="div" className="">
      <div className="flex items-center">
        <Menu.Button 
            onClick={handleClick}
            className="flex max-w-xs items-center rounded-full bg-white dark:bg-dark-raised
                border-[2px] border-neutral-300 dark:border-neutral-700 text-sm
                dark:hover:border-neutral-500 hover:border-neutral-400">
          <div className={classNames(
                playerText || !sceneName ? 'hidden md:block pl-4 pr-2 text-[15px] font-extrabold text-neutral-700 dark:text-neutral-100' : 
                    'hidden md:block tx-md font-extrabold text-neutral-700 dark:text-neutral-100',
              )}
          >
          {/* // className="pl-4 pr-2 tx-md font-extrabold text-neutral-700 dark:text-neutral-100"> */}
            {/* {playerText} */}
            {sceneName ? playerText : user ? user.display_name : 'Visitor'}
          </div>
          <span className="sr-only">Open user menu</span>
          <img className="h-12 w-12 rounded-full" src={user ? 
              profilePictureUrl(user.id) : 
              outsiderImage} alt="" 
          />
        </Menu.Button>
      </div>
        {playerActions && playerActions.length > 0 && (
        <Menu.Items className="absolute right-3 z-20 rounded-lg mt-2 max-w-[250px]
            origin-top-right bg-light-backdrop dark:bg-dark-backdrop border-2
            border-neutral-400 dark:border-neutral-600">
          {playerActions.sort((a, b) => a.rank - b.rank).map((action, index) => {
            const ActionComponent = action.component;
            return <div key={index}><ActionComponent key={action.type} entityType={action.entityType} /></div>;
          })}
        </Menu.Items>
        )}
    </Menu>
  );
}

PlayerButton.propTypes = {
};

export default PlayerButton;
