import LIGHTING from '../scenes/lighting';

export function generateStyle(lightingTheme) {
  let modalStyle = {
    content: {
      flex: 1,
      top: '50%',
      left: '50%',
      width: '28rem',
      height: '24rem',
      marginRight: '0px',
      marginLeft: '0px',
      paddingLeft: '0px',
      paddingRight: '0px',
      transform: 'translate(-50%, -50%)',
      borderRadius: '8px',
      border: '0px solid',
      borderColor: 'rgba(55, 55, 55, 1)',
    }, 
    overlay: {zIndex: 1000}
  };
  if (lightingTheme === LIGHTING.DARK) {
    modalStyle.content.backgroundColor = 'rgba(29, 29, 29, 1)';
    modalStyle.content.color = 'rgba(220, 220, 220, 1)';
    modalStyle.overlay.backgroundColor = 'rgba(0, 0, 0, .88)';
  } else {
    modalStyle.content.backgroundColor = 'rgba(245, 245, 245, 1)';
    modalStyle.content.color = 'rgba(23, 23, 23, 1)';
    modalStyle.overlay.backgroundColor = 'rgba(220, 220, 220, .95)';
  }

  // Use media queries to change the width for mobile-sized screens
  if (window.innerWidth <= 768) { // You can adjust this value to your preferred breakpoint
    modalStyle.content.width = '100%';
  }

  return modalStyle;
}
