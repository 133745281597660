import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { goToTwitterAuth } from '../utilities/twitter';

function LoginWithXButton(props) {
  const [clicked, setClicked] = useState(false);
  const loadingAnimation = <div className="w-14 h-14 border-t-4 border-primary rounded-full animate-spin-slow"></div>;
  const button = (
      <button onClick={() => {
            setClicked(true);
            goToTwitterAuth('user')}} 
          className='text-lg text-center w-[22rem] font-medium bg-primary py-3 rounded-xl'>
        Login with X to Enter Giveaway
      </button>
  );
  const render = clicked ? loadingAnimation : button;
  return render;
}

LoginWithXButton.propTypes = {
}

export default LoginWithXButton
