import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@headlessui/react';

import LIGHTING from '../scenes/lighting';
import { LightingContext } from '../routes/Root';

function PostMenu(props) {
  const { lightingTheme } = useContext(LightingContext);
  let optionsImage = '/images/menu.png';
  if (lightingTheme === LIGHTING.DARK) {
    optionsImage = '/images/menu-dark.png';
  }

  function handleItemClick(event) {
    event.preventDefault();
    props.onDeleteClick(props.id)
  }

  return (
    <Menu as="div" className="relative">
      <Menu.Button
          className="focus:outline-none rounded-full">
        <div 
            className="flex gap-x-1 ml-[1.5px] leading-tight">
          <img className='h-4 w-4 mt-[1px]' src={optionsImage} />
          <span className='text-neutral-600 ml-1 hover:text-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300'>Options</span>
        </div>
      </Menu.Button>
      <Menu.Items 
          className="absolute left-0 z-10 rounded-lg mt-2 w-48 origin-top-left 
              bg-light-backdrop py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none
              dark:bg-dark-backdrop border-2 border-neutral-400 dark:border-neutral-700">
        <Menu.Item>
            {({ active }) => (
              <a
                // href=""
                //${active && 'bg-gray-100 dark:bg-neutral-800'}
                className={`block px-4 py-2 text-sm text-neutral-600 dark:text-neutral-300 font-bold hover:cursor-pointer`}
                onClick={handleItemClick}
              >
                Delete post
              </a>
            )}
          </Menu.Item>
      </Menu.Items>
    </Menu>
  );
}

PostMenu.propTypes = {
  id: PropTypes.number.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default PostMenu;
