import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import LIGHTING from '../scenes/lighting';
import { UserContext, LightingContext } from '../routes/Root';
import { SignUpContext } from '../components/SignUpModal';

import { connectTwitterV1, goToTwitterAuth } from '../utilities/twitter';

function LoginWithTwitterButton(props) {
  const { 
    twitterConnected, setTwitterConnected,
    setFacebookConnected, setPhoneConnected,
    twitterLoading, setTwitterLoading,
    setShowSignUpModal
  } = useContext(SignUpContext);
  const { setUser } = useContext(UserContext);
  const { lightingTheme } = useContext(LightingContext);

  const navigate = useNavigate();

  let xLogoImage = '/images/twitter/x.png';
  if (lightingTheme === LIGHTING.DARK) {
    xLogoImage = '/images/twitter/x-dark.png';
  }

  let buttonClass, connectText;
  if (twitterConnected) {
    connectText = 'Connected';
    buttonClass = `flex flex-col h-[11rem] w-[170px] mr-4 border-2 cursor-pointer bg-neutral-50
        dark:bg-neutral-900 text-neutral-600
        border-neutral-400 dark:text-neutral-100 opacity-50 rounded-lg items-center justify-center p-4`
  } else if (twitterLoading) {
    connectText = 'Connecting';
    buttonClass = `flex flex-col h-[11rem] w-[170px] mr-4 border-2 cursor-pointer bg-neutral-50
        dark:bg-neutral-900 text-black dark:text-neutral-200
        border-neutral-200 rounded-lg items-center justify-center p-4 animate-pulse`
  } else {
    connectText = 'Connect';
    buttonClass = `flex flex-col h-[11rem] w-[170px] mr-4 border-2 cursor-pointer bg-neutral-50 
        dark:bg-neutral-900 text-black
        dark:border-neutral-400 border-neutral-500 dark:text-neutral-200 rounded-lg items-center justify-center p-4 hover:border-primary`
  }

  // async function twitterCallback() {
  //   const urlSearchParams = new URLSearchParams(window.location.search);
  //   const code = urlSearchParams.get('code');
  //   if (code) {
  //     try {
  //       setTwitterLoading(true);
  //       const receivedState = urlSearchParams.get('state');
  //       navigate(location.pathname);
  //       const storedState = JSON.parse(localStorage.getItem("twitterAuthState"));
  //       if (receivedState === storedState.random) {
  //         const { entity: user, phoneConnected, facebookConnected } = await connectTwitter(code);
  //         if (user) setTwitterConnected(true);
  //         setFacebookConnected(Boolean(facebookConnected));
  //         setPhoneConnected(Boolean(phoneConnected));
  //         if (facebookConnected && phoneConnected) {
  //           setUser(user);
  //           localStorage.setItem('user', JSON.stringify(user));
  //         } else {
  //           props.setLoggingInUserId(user.id);
  //         }
  //         setTwitterLoading(false);
  //       } else {
  //           throw Error('Incorrect state received from Twitter response.')
  //       }
  //     } catch (error) {
  //       setShowSignUpModal(false);
  //       // Remove query params set by Twitter
  //       navigate(location.pathname);
  //       alert('Error connecting Twitter account');
  //       console.error(error);
  //     }
  //   }
  // }

  async function completeSignin() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const tempToken = urlSearchParams.get('tempToken');
    navigate(location.pathname);
    const { entity: user, phoneConnected, facebookConnected } = await connectTwitterV1(tempToken);
    if (user) setTwitterConnected(true);
    // setFacebookConnected(Boolean(facebookConnected));
    setFacebookConnected(true);
    // setPhoneConnected(Boolean(phoneConnected));
    setPhoneConnected(true);
    if (facebookConnected && phoneConnected) {
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      navigate(localStorage.getItem('context'));
    } else {
      props.setLoggingInUser(user);
    }
  }

  useEffect(() => {
    completeSignin()
  }, [])

  return (
    <div
      className={buttonClass} onClick={() => { if (!twitterLoading) { setTwitterLoading(true); goToTwitterAuth();}}} >
    <div className='rounded-full mb-2 text-lg font-semibold'>X</div>
    <img src={xLogoImage} className='w-16 h-16 mb-1 p-1.5' />
    <p className="
        flex-none mx-3 px-4 py-1.5 text-md text-center font-semibold rounded-lg
         cursor-pointer">
      {connectText}
    </p>
</div>
  );
}

LoginWithTwitterButton.propTypes = {
  // onClick: PropTypes.func.isRequired,
  // newUserId: PropTypes.number.isRequired,
  setLoggingInUser: PropTypes.func.isRequired,
}

export default LoginWithTwitterButton;
