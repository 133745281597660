import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Menu } from '@headlessui/react';

function WorldAction(props) {
  return (
    <Menu.Item key={props.action.name}>
      {({ active }) => (
        <Link 
          key={props.action.name}
          to={props.action.route}
          onClick={props.action.onClick}
          className="block px-4 py-2 text-sm text-neutral-700 dark:text-neutral-200 font-bold hover:cursor-pointer"
        >
          {props.action.name}
        </Link>
      )}
    </Menu.Item>
  );
}

WorldAction.propTypes = {
  action: PropTypes.object.isRequired,
};

export default WorldAction;