import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { requestCode } from '../model/phone';

function ConnectPhoneInput(props) {
  const [value, setValue] = useState('');
  const [firstThreeDigits, setFirstThreeDigits] = useState('');
  const [nextThreeDigits, setNextThreeDigits] = useState('');
  const [nextFourDigits, setNextFourDigits] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const [shownCountryCode, setShownCountryCode] = useState('+' + countryCode);
  const [shownNumber, setShownNumber] = useState('');
  const [phoneNumberToSend, setPhoneNumberToSend] = useState('');

  function formatPhoneNumber(phoneNumber) {
    // Remove non-digit characters from the input
    let rawPhoneNumber = phoneNumber.replace(/\D/g, '');
    rawPhoneNumber = rawPhoneNumber.slice(0, 10);

    // Format the cleaned input into a readable phone number format
    let formattedNumber = '';
    if (rawPhoneNumber.length > 0) {
      if (rawPhoneNumber.length > 3) {
        formattedNumber += '(' + rawPhoneNumber.slice(0, 3) + ') ';
      }
      if (rawPhoneNumber.length > 6) {
        formattedNumber += rawPhoneNumber.slice(3, 6) + '-';
      }
      formattedNumber += rawPhoneNumber.slice(6);
    }

    return formattedNumber;
  }

  useEffect(() => {
    setShownNumber('+' + countryCode.replace(/\D/g, '') + ' ' + formatPhoneNumber(value));
    props.setPhoneNumber('+' + countryCode.replace(/\D/g, '') + value.replace(/\D/g, '').slice(0,10));
    setPhoneNumberToSend('+' + countryCode.replace(/\D/g, '') + value.replace(/\D/g, '').slice(0,10));
  }, [value, countryCode]);

  function handleSubmit(event) {
    event.preventDefault();
    // ERROR HANDLILNG PLESSSS
    requestCode(phoneNumberToSend);
    props.setStage('VERIFY');
  }

  return (
    <div className='flex flex-col items-center justify-center gap-10'>
      <p className='text-xl font-semibold'>{shownNumber}</p>
      <div className='flex flex-col'>
        <div className='flex'>
          <input type='tel' onChange={(event) => setCountryCode(event.target.value)} placeholder='+1' value={countryCode}
              className='text-neutral-800 rounded-tl-lg w-[65px] border-0 bg-neutral-200' maxLength="4"/>
          <input
              type='tel'
              onChange={(event) => setValue(event.target.value)} 
              placeholder='Phone number'
              pattern='(\+?\d{1,3})?[\s-]?\d{3}[\s-]?\d{7}'
              className='text-neutral-800 rounded-tr-lg border-0'
              maxLength="14" />
        </div>
        <button 
            onClick={handleSubmit} 
            className='cursor-pointer bg-primary hover:bg-green-500 text-neutral-50 p-1.5 rounded-b-lg font-semibold' 
        >
          Verify by sending SMS code
        </button>
      </div>
    </div>
  )
}

ConnectPhoneInput.propTypes = {
  setStage: PropTypes.func.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
  // phoneNumber: PropTypes.string.isRequired,
}

export default ConnectPhoneInput;
