import React, { Component } from 'react';
// import SignUpForm from '../components/SignUpForm.js';

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <></>
      // <SignUpForm/>
    );
  }
}

export default SignUp;
