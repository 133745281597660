import { React, useContext } from 'react';
import { Outlet, Link } from "react-router-dom";
import { UserContext } from '../routes/Root';
import NavigationBar from '../components/NavigationBar';

function MainStage() {
  const { referrer } = useContext(UserContext);
  let marginTop = '';
  // if (!referrer) { marginTop = 'mt-14' }

  return (
    <div className='flex flex-col h-screen justify-between'>
      {!referrer && <NavigationBar />}
      <main className={`grow bg-light-backdrop dark:bg-dark-backdrop ${marginTop}`}>
        <Outlet />
      </main>
      <Link
          to={'/privacy'}
          className='pl-5 text-sm dark:text-neutral-400 text-neutral-600 cursor-pointer hover:text-neutral-900 hover:dark:text-neutral-100'>
        Privacy policy
      </Link>
    </div>
  )
}

export default MainStage;