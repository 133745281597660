import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Menu } from '@headlessui/react';
import { profilePictureUrl } from '../utilities/general';
import { formatTimeDifference } from '../utilities/formatting.js';
import PostMenu from './PostMenu';

import { UserContext } from '../routes/Root';

function ReplyCardHeading(props) {
  const { user } = useContext(UserContext);

  return (
    <div className="flex justify-between items-start pt-0.5">
      <div className="flex gap-x-2 items-center">
        <div className="flex leading-tight">
          <span className="font-semibold text-neutral-800 dark:text-neutral-100">
            {props.authorDisplayName}
          </span>
          <span className="mx-1 text-neutral-600 dark:text-neutral-400">
            @{props.authorHandle} . 
          </span>
          <span className="cursor-default text-neutral-600 dark:text-neutral-400 text-sm hover:cursor-pointer">{formatTimeDifference(props.timestamp)}</span>
        </div>
      </div>
      {user && user.id === props.authorId &&
        <PostMenu id={props.id} onDeleteClick={props.onDeleteClick} />
      }
      {/* <div className="px-4 py-1 text-sm hover:cursor-pointer rounded-lg font-bold text-white bg-primary hover:bg-green-400 drop-shadow-[2px_2px_3px_rgba(0,0,0,0.30)]">Follow me on Twitter</div> */}
    </div>
  );
}

ReplyCardHeading.propTypes = {
  id: PropTypes.number.isRequired,
  authorId: PropTypes.number.isRequired,
  authorHandle: PropTypes.string.isRequired,
  authorDisplayName: PropTypes.string.isRequired,
  pictureUrl: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  tribuneHandle: PropTypes.string,
  outsideTribune: PropTypes.bool,
  onDeleteClick: PropTypes.func.isRequired,
};

ReplyCardHeading.defaultProps = {
  outsideTribune: false
};

export default ReplyCardHeading;
