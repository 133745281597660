

import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import autoAnimate from '@formkit/auto-animate'

import LoginWithFacebookButton from './LoginWithFacebookButton';
import LoginWithTwitterButton from './LoginWithTwitterButton';

import { SignUpContext } from '../components/SignUpModal';

function ConnectSocials(props) {
  const { 
    twitterConnected, setTwitterConnected,
    twitterLoading, setTwitterLoading,
  } = useContext(SignUpContext);
  
  const body = useRef(null)
  useEffect(() => {
    body.current && autoAnimate(body.current)
  }, [body])

  return (
    <div ref={body} className="flex items-center justify-center">
      <LoginWithTwitterButton setLoggingInUser={props.setLoggingInUser} />
      {/* {twitterConnected && <LoginWithFacebookButton loggingInUser={props.loggingInUser} />} */}
    </div>
  )
}

ConnectSocials.propTypes = {
  loggingInUser: PropTypes.object,
  setLoggingInUser: PropTypes.func.isRequired,
}

export default ConnectSocials;
