import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';
import { profilePictureUrl } from '../utilities/general';

function ReplyCardSideBar(props) {
  return (
    <div className="flex flex-col items-center rounded-l bg-light-backdrop dark:bg-dark-backdrop px-3 pt-1">
      <img src={profilePictureUrl(props.authorId)} className='w-10 h-10 rounded-full cursor-pointer'/>
    </div>
  );
}

ReplyCardSideBar.propTypes = {
  points: PropTypes.number.isRequired,
  hasVoted: PropTypes.bool.isRequired,
  postId: PropTypes.number.isRequired,
  authorId: PropTypes.number.isRequired,
  onVoteClick: PropTypes.func.isRequired,
  voteThresholdPercent: PropTypes.number.isRequired,
  authorProfilePictureUrl: PropTypes.string.isRequired
};

export default ReplyCardSideBar;
