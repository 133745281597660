import { React, useState, useEffect, createContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import IntroStage from '../components/IntroStage';
import MainStage from '../components/MainStage';
import SignUpModal from '../components/SignUpModal.js';
import NotificationsModal from '../components/NotificationsModal.js';
import { endSession } from '../model/auth';
import { BrowserView, MobileView, isBrowser, OsTypes, getSelectorsByUserAgent, isAndroid, isIOS, isChrome, isSafari} from 'react-device-detect';

import autoAnimate from '@formkit/auto-animate'

import FlashlightPlayerAction from '../components/FlashlightPlayerAction';
import SoundPlayerAction from '../components/SoundPlayerAction';
import SignOutPlayerAction from '../components/SignOutPlayerAction';
import SignInPlayerAction from '../components/SignInPlayerAction';

import SCENE_NAMES from '../scenes/sceneNames';
import SCENES from '../scenes/scenes';
import LIGHTING from '../scenes/lighting';
import LandingModal from '../components/LandingModal';

const UserContext = createContext('user');
const PlayerInventoryContext = createContext('player-inventory');
const WorldContext = createContext('world')
const PlayerContext = createContext('player');
const SceneContext = createContext('scene');
const StoryContext = createContext('story');
const LightingContext = createContext('lighting');

function Root(props) {
  const [user, setUser] = useState(null);

  const [showNotificationsModal, setShowNotificationsModal] = useState(false);

  const [lightingTheme, setLightingTheme] = useState(localStorage.getItem('theme') === 'DARK' ? LIGHTING.DARK : LIGHTING.LIGHT);

  const [playerContainer, setPlayerContainer] = useState({ name: 'Nothing', maxCapacity: 0 });
  const [isLoggedIn, setIsLoggedIn] = useState(user ? true : false);
  const [playerInventory, setPlayerInventory] = useState([]);

  const [worldImage, setWorldImage] = useState(() => null);
  const [worldText, setWorldText] = useState(() => null);
  const [worldActions, setWorldActions] = useState([]);

  const [playerText, setPlayerText] = useState('');

  const [playerActions, setPlayerActions] = useState([
    { type: 'flashlight', component: FlashlightPlayerAction, rank: 1 },
    // { type: 'sound', component: SoundPlayerAction, rank: 2 },
  ]);

  const [playerOnClick, setPlayerOnClick] = useState(null);

  const [sceneName, setSceneName] = useState(null);
  const [sceneState, setSceneState] = useState(null);

  const [storyText, setStoryText] = useState('');
  const [storyDismiss, setStoryDismiss] = useState(() => null);

  const location = useLocation();

  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const referrer = urlSearchParams.get('ref');

  // TODO: finish intro scene
  const skipIntro = true;

  function signOut() {
    setUser(null);
    localStorage.removeItem('user');
    endSession();
  }

  // function toggleShowSignUpModal() {
  //   setShowSignUpModal(showSignUpModal => {
  //     return !showSignUpModal;
  //   });
  // }

  function toggleLighting() {
    setLightingTheme(lightingTheme => {
      if (lightingTheme === LIGHTING.DARK) {
        localStorage.setItem('theme', LIGHTING.LIGHT);
        return LIGHTING.LIGHT;
      } else {
        localStorage.setItem('theme', LIGHTING.DARK);
        return LIGHTING.DARK;
      }
    });
    document.documentElement.classList.toggle('dark')
  }

  useEffect(() => {
    // setWorldText(location.pathname);
    setWorldText('Tribune');
    setWorldImage('/images/icon.png');
  }, [location]);

  useEffect(() => {
    setIsLoggedIn(user ? true : false);
  }, [user])

  useEffect(() => {
    // Intro sequence always starts dark
    if (sceneName === SCENE_NAMES.INTRO && sceneState === SCENES[SCENE_NAMES.INTRO].ASLEEP && !user && !LIGHTING.DARK) {
      localStorage.setItem('theme', LIGHTING.DARK);
      setLightingTheme(LIGHTING.DARK);
      document.documentElement.classList.add('dark')
    }
    // twitterCallback();
    // const urlSearchParams = new URLSearchParams(window.location.search);
    // const code = urlSearchParams.get('code');
    if (props.signin) setShowSignUpModal(true);
  }, []);

  function updatePlayerActions(user) {
    setPlayerActions(playerActions => {
      if (user) {
        const newAction = { type: 'sign-out', component: SignOutPlayerAction, rank: 99 };
        const updatedActions = playerActions.filter(action => action.type !== 'sign-in');
        updatedActions.push(newAction);
        return updatedActions;
      } else {
        const newAction = { type: 'sign-in', component: SignInPlayerAction, rank: 99, entityType: 'tribune' };
        const updatedActions = playerActions.filter(action => action.type !== 'sign-out');
        updatedActions.push(newAction);
        return updatedActions;
      }
    });
  }

  // Does OneSignal permission get updated when user disables notifications from settings?
  // How to check if user disabled notifications natively?
  async function showModalIfNotSubscribed() {
    window.OneSignal.push(async function() {
      const subscribed = await window.OneSignal.Notifications.permission;

      if (!subscribed) { 
        setShowNotificationsModal(true);
      }
    });
  }

  useEffect(() => {
    updatePlayerActions(user);
    setLoading(false);
  }, [user]);

  useEffect(() => {
    if ('matchMedia' in window && window.matchMedia('(display-mode: standalone)').matches) {
      showModalIfNotSubscribed();
    }

    // Check if cookie still valid, then set user, else set null?
    const user = localStorage.getItem('user');
    if (user !== null && user !== 'undefined') {
      setUser(JSON.parse(user));
      if ('matchMedia' in window && window.matchMedia('(display-mode: standalone)').matches) {
        if (window.OneSignal) {
          window.OneSignal.push(async function() {
            await window.OneSignal.User.addAlias("userId", String(JSON.parse(user).id));
          });
        }
      }
    }
  }, []);



  return (
    <WorldContext.Provider value={{ worldImage, setWorldImage, worldText, setWorldText, worldActions, setWorldActions }} >
    <PlayerContext.Provider value={{ playerText, setPlayerText, playerActions, setPlayerActions, playerOnClick, setPlayerOnClick }} >
    <SceneContext.Provider value={{ sceneName, setSceneName, sceneState, setSceneState }} >
    <StoryContext.Provider value={{ storyText, setStoryText, storyDismiss, setStoryDismiss }} >
    <LightingContext.Provider value={{ lightingTheme, toggleLighting }} >
    <PlayerInventoryContext.Provider value={{playerInventory, setPlayerInventory, playerContainer, setPlayerContainer }} >
    <UserContext.Provider value={{ user, setUser, loading, referrer, setShowSignUpModal, signOut }} >
      <SignUpModal
          showModal={showSignUpModal}
          setShowModal={setShowSignUpModal}
      />
      <LandingModal isLoggedIn={isLoggedIn} />
      <div className="bg-light-backdrop dark:bg-dark-backdrop">
        
        {!loading && (user || skipIntro ? <MainStage /> : <IntroStage />)}
      </div>
      <NotificationsModal
        showModal={showNotificationsModal}
        setShowModal={setShowNotificationsModal}
      />
    </UserContext.Provider>
    </PlayerInventoryContext.Provider>
    </LightingContext.Provider>
    </StoryContext.Provider>
    </SceneContext.Provider>
    </PlayerContext.Provider>
    </WorldContext.Provider>
  );
}  

Root.propTypes = {
  signin: PropTypes.bool
}

export {
    UserContext,
    SceneContext,
    WorldContext,
    PlayerContext, 
    StoryContext,
    LightingContext
};
export default Root;
