import { React, useContext, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';

import SCENE_NAMES from '../scenes/scenes';

import { 
    UserContext,
    SceneContext,
    StoryContext,
    LightContext
} from '../routes/Root';

import NavigationTabs from './NavigationTabs';
import WorldButton from './WorldButton';
import PlayerButton from './PlayerButton';
import StoryModal from '../components/StoryModal.js';

let tabs = [
  // { name: 'Home', route: '/', current: location.pathname == '/' ? true : false },
  // { name: 'Tribunes', route: '/discover', current: location.pathname == '/discover' ? true : false },
  // { name: 'Giveaways', route: '/discover', current: location.pathname == '/discover' ? true : false },
  // { name: 'Forums', route: 'forums', current: location.pathname == '/forums' ? true : false },
];

function NavigationBar(props) {
  const { user } = useContext(UserContext);
  const { sceneName } = useContext(SceneContext);
  const [adminAdded, setAdminAdded] = useState(false);

  // Add secret Admin tab
  if (user && user.is_admin == true && !adminAdded) {
    const tempTabs = tabs;
    tempTabs.push({ name: 'Admin', route: 'admin', current: location.pathname == '/admin' ? true : false });
    tabs = tempTabs;
    setAdminAdded(true);
  }

  return (
    <Disclosure as="nav" className="flex items-center justify-center space-between bg-light-backdrop dark:bg-dark-backdrop fixed w-screen top-0 
        border-b border-neutral-300 dark:border-neutral-800 px-3 z-20">
          {/* sm:px-6 lg:px-8" */}
        <StoryModal />
      <div className="flex h-14 grow items-center justify-between">
        <div className="hidden md:block">
            {/* <WorldButton /> */}
        </div>
        <div className="lg:absolute lg:left-1/2 lg:transform lg:-translate-x-1/2">
          {!sceneName && <NavigationTabs tabs={tabs} />}
        </div>
        <div className="flex">
          <PlayerButton />
        </div>
      </div>
    </Disclosure>
  );
}

NavigationBar.propTypes = {
  // scene: PropTypes.object
};

export default NavigationBar;
