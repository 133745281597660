import { React, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, OsTypes, getSelectorsByUserAgent, isAndroid, isIOS, isChrome, isSafari} from 'react-device-detect';

import { UserContext } from '../routes/Root';

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

function NotificationsModal(props) {
  const { user } = useContext(UserContext);

  const [ title, setTitle ] = useState();
  const [ text, setText ] = useState();
  // const [ image, setImage ] = useState();

  async function promptSubscribe() {
    window.OneSignal.push(async function() {
      await window.OneSignal.Notifications.requestPermission();
    });
  }

  async function handleAccept(event) {
    await promptSubscribe();
    props.setShowModal(false);
  }

  useEffect(() => {
    if (isIOS) {
      setTitle('Turn on Notifications');
      setText(<span>
        To use this app, turn on notifications.
      </span>);
    } else if (isAndroid) {
      setTitle('Turn on Notifications');
      setText(<span>
        To use this app, turn on notifications.
      </span>);
      
    }
  }, [props.showModal])

  return (
    <Modal 
        open={props.showModal}
        contentLabel="Minimal Modal Example"
        showCloseIcon={false}
        blockScroll={true}
        classNames={{
          // sm:top-[0%] md:top-[4%]
          modal: `inset-x-0 mx-auto !p-0  border-[0.5px] border-neutral-700 dark:border-neutral-800
            bg-light-backdrop dark:bg-dark-backdrop rounded-lg top-[27rem] transform
            border-neutral-400 text-neutral-800 dark:text-neutral-300 w-screen sm:w-4/5 md:w-3/4 lg:max-w-[40rem]`,
          overlay: `bg-neutral-800 bg-opacity-80`,
        }}
    >
      <div className='p-4 flex flex-col items-center'>
        <div className='text-2xl font-bold mb-1 text-center mb-2'>{title}</div>
        {/* {image} */}
        <div className='text-lg leading-6 text-center'>
          {text}
        </div>
        <div 
          className='bg-primary text-black w-[22rem] text-center px-3 py-2 mt-4 rounded-lg text-xl font-bold'
          onClick={handleAccept}
        >
          Okay
        </div>
      </div>
    </Modal>
  );
}

NotificationsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
}

export default NotificationsModal;
