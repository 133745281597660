import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';

function PostCardSideBar(props) {
  let progressBarClass = 'border-solid border-2 rounded-lg border-primary rounded-lg flex flex-col-reverse drop-shadow-[2px_2px_3px_rgba(0,0,0,0.30)]';
  let energyIconClass = 'cursor-not-allowed';
  let pointsClass = 'font-semibold text-neutral-800 dark:text-neutral-100 cursor-default';
  let containerClass = 'flex flex-col items-center rounded-l';
  if (props.reply) {
    progressBarClass += ' h-20 w-9 mb-2.5';
    energyIconClass += ' w-[30px] h-[30px]';
    pointsClass += ' mb-2'
    containerClass += ' px-3 pt-3.5'
  } else {
    progressBarClass += ' h-20 w-9 mb-2.5';
    energyIconClass += ' w-[30px] h-[30px]';
    pointsClass += ' mb-2'
    containerClass += ' px-3'
  }

  if (!props.hasVoted) {
    energyIconClass += ' opacity-50 hover:opacity-100 cursor-pointer';
  }

  return (
    <div className='flex flex-col items-center rounded-l ml-5 mt-3'>

        <p className='font-semibold text-neutral-800 dark:text-neutral-100 cursor-default mb-2'>
          {props.points}
        </p>
        <div className="border-solid rounded-lg mb-2.5 w-7 h-[58px] border-2 rounded-lg border-primary bg-light-backdrop
          dark:bg-dark-backdrop flex flex-col-reverse">
          <ProgressBar progressPercentage={props.voteThresholdPercent} />
        </div>
        <img src='/images/fire-energy.png' className='cursor-not-allowed w-[30px] h-[30px]' />
    </div>
  );
}

PostCardSideBar.propTypes = {
  points: PropTypes.number.isRequired,
  reply: PropTypes.bool,
  hasVoted: PropTypes.bool.isRequired,
  postId: PropTypes.number.isRequired,
  authorId: PropTypes.number.isRequired,
  onVoteClick: PropTypes.func.isRequired,
  voteThresholdPercent: PropTypes.number.isRequired,
};

export default PostCardSideBar;
