import { React, useState, useEffect, useContext, useRef } from 'react'
import { Outlet, useLocation, useParams, useOutletContext } from 'react-router-dom';

import { 
  WorldContext,
  PlayerContext,
  SceneContext,
  StoryContext,
  LightingContext
} from '../routes/Root';

import SCENE_NAMES from '../scenes/sceneNames';
import SCENES from '../scenes/scenes';
import LIGHTING from '../scenes/lighting';

import NavigationBar from '../components/NavigationBar';
import WorldAction from '../components/WorldAction';
import PlayerAction from '../components/PlayerAction';

const lightSwitchSound = new Audio("/audio/light-switch.wav");
// const ominousDrumSound = new Audio("/audio/ominous-drum.wav");

const findItemSound = new Audio("/audio/find-item.wav");
const menuNoopSound = new Audio("/audio/menu-noop.wav");
const intercomButtonSound = new Audio("/audio/intercom-button.wav");

const INTRO = 'INTRO';
const STATES = {
  ASLEEP: 'ASLEEP',
  READING: 'READING',
  LOOKING_AROUND: 'LOOKING_AROUND',
  KILLER_CHOICE: 'KILLER_CHOICE',
  ACHIEVER_CHOICE: 'ACHIEVER_CHOICE',
  EXPLORER_CHOICE: 'EXPLORER_CHOICE',
  SOCIALIZER_CHOICE: 'SOCIALIZER_CHOICE',
};

const PLAYER_TYPES = {
  KILLER: 'KILLER',
  ACHIEVER: 'ACHIEVER',
  EXPLORER: 'EXPLORER',
  SOCIALIZER: 'SOCIALIZER'
}

function IntroStage(props) {
  const { sceneName, setSceneName, sceneState, setSceneState } = useContext(SceneContext);
  const story = useContext(StoryContext);
  const world = useContext(WorldContext);
  const player = useContext(PlayerContext);
  const { lightingTheme, toggleLighting } = useContext(LightingContext);
  const lightingThemeRef = useRef(lightingTheme);

  let playedAsleep = false;

  const ominousDrumSound = new Audio("/audio/ominous-drum.wav");

  const INTRO_SCENE = {
    ASLEEP: {
      onPlay: () => {
        // Set the mood
        playedAsleep = true;
        player.setPlayerText('Wake up');
        if (lightingThemeRef.current !== LIGHTING.DARK) {
          toggleLighting();
          document.documentElement.classList.add('dark')
        }
        player.setPlayerOnClick(() => {return () => INTRO_SCENE[STATES.ASLEEP].next()});
      },
      next: () => {
        setSceneState(STATES.READING);
        INTRO_SCENE.READING.onPlay();
      },
    },
    READING: {
      onPlay: () => {
        player.setPlayerOnClick(() => {return () => null});
        ominousDrumSound.play();
        player.setPlayerText('');
        story.setStoryText(`
            You wake up in a mysterious dark cell
            with no idea how you got here.`);
        story.setStoryDismiss(() => { return () => {INTRO_SCENE.READING.next();}});
      },
      next: () => {
        setSceneState(STATES.LOOKING_AROUND);
        INTRO_SCENE.LOOKING_AROUND.onPlay();
      },
    },
    LOOKING_AROUND: {
      onPlay: () => {
        story.setStoryText('');
        world.setWorldText('Mysterious Dark Cell');
        world.setWorldImage('/images/unknown.png');
        world.setWorldActions([
          <WorldAction key={1} 
              action={{
                name: "I can't see anything",
                onClick: () => { menuNoopSound.play(); setTimeout(() => INTRO_SCENE.LOOKING_AROUND.next(), 3500); } 
              }} 
          />
        ]);
      },
      next: () => {
        setSceneState(STATES.THINKING);
        INTRO_SCENE.THINKING.onPlay();
      },
    },
    THINKING: {
      onPlay: () => {
        player.setPlayerText('You suddenly have the thought...');
        player.setPlayerActions([
          <PlayerAction key='1' action={{name: "Whoever's behind this will pay", playerType: 'killer', route: '#',
              onClick: () => INTRO_SCENE.THINKING.next(PLAYER_TYPES.KILLER) }}/>,
          <PlayerAction key='2' action={{name: 'I need to find my things', playerType: 'achiever', route: '#',
              onClick: () => INTRO_SCENE.THINKING.next(PLAYER_TYPES.ACHIEVER) }}/>,
          <PlayerAction key='3' action={{name: 'What does this switch do?', playerType: 'explorer', route: '#',
              onClick: () => { lightSwitchSound.play(); toggleLighting(); INTRO_SCENE.THINKING.next(PLAYER_TYPES.EXPLORER)} }}/>,
          <PlayerAction key='4' action={{name: 'Is anyone else here?', playerType: 'socializer', route: '#',
              onClick: () => INTRO_SCENE.THINKING.next(PLAYER_TYPES.SOCIALIZER) }}/>,
        ]);
      },
      next: (playerType) => {
        if (playerType === 'KILLER') {
          setSceneState(STATES.KILLER_CHOICE);
          INTRO_SCENE.KILLER_CHOICE.onPlay();
        } else if (playerType === 'ACHIEVER') {
          setSceneState(STATES.ACHIEVER_CHOICE);
          INTRO_SCENE.ACHIEVER_CHOICE.onPlay();
        } else if (playerType === 'EXPLORER') {
          setSceneState(STATES.EXPLORER_CHOICE);
          INTRO_SCENE.EXPLORER_CHOICE.onPlay();
        } else {
          setSceneState(STATES.SOCIALIZER_CHOICE);
          INTRO_SCENE.SOCIALIZER_CHOICE.onPlay();
        }
      },
    },
    KILLER_CHOICE: {
      onPlay: () => {
        player.setPlayerActions([]);
        player.setPlayerText('killer');
      },
      next: () => {
        // console.log('end of story');
      },
    },
    ACHIEVER_CHOICE: {
      onPlay: () => {
        player.setPlayerActions([]);
        player.setPlayerText('achiever');
      },
      next: () => {
        // console.log('end of story');
      },
    },
    EXPLORER_CHOICE: {
      onPlay: () => {
        player.setPlayerActions([]);
        player.setPlayerText('explorer');
      },
      onLightingUpdate: () => {
        world.setWorldText(() => lightingThemeRef.current === LIGHTING.DARK ? 'Mysterious Dark Cell' : 'Mysterious Cell');
        world.setWorldImage(() => lightingThemeRef.current === LIGHTING.DARK ? '/images/unknown.png' : '/images/unknown-dark.png');
        world.setWorldActions([
          <WorldAction key={1} 
              action={{
                name: "I was blind, and now I see",
                onClick: null 
              }} 
          />
        ]);
      },
      next: () => {
        // console.log('end of story');
      },
    },
    SOCIALIZER_CHOICE: {
      onPlay: () => {
        player.setPlayerActions([]);
        player.setPlayerText('socializer');
      },
      next: () => {
        // console.log('end of story');
      },
    },
  }

  useEffect(() => {
    setSceneName(INTRO);
    setSceneState(STATES.ASLEEP);
    if (!playedAsleep) {
      INTRO_SCENE[STATES.ASLEEP].onPlay();
    }
  }, []);

  useEffect(() => {
    lightingThemeRef.current = lightingTheme;
    sceneState && 'onLightingUpdate' in INTRO_SCENE[sceneState] && INTRO_SCENE[sceneState].onLightingUpdate();
  }, [lightingTheme]);

  useEffect(() => {
    console.log('onPlays' in INTRO_SCENE[STATES.ASLEEP]);
  }, [sceneState]);

  return (
    <>
      <NavigationBar />
      <div>
        <main className="bg-light-backdrop dark:bg-dark-backdrop">
          
        </main>
      </div>
    </>

    // <div>
    //   <div className="flex justify-center bg-light-backdrop border-neutral-300 dark:bg-dark-backdrop border-b dark:border-neutral-800 h-24">
    //     <div className="flex w-2/5">

          
    //       <div className="flex items-center">
    //         <img className="h-20 w-20 rounded-full bg-neutral-500" src="/images/icon.png" alt="" />
    //       </div>
    //       <div className="flex flex-col flex-grow justify-center pl-5">
    //         {/* <div className="flex items-center"> */}
    //         <div className="">
    //           <span className="text-3xl font-bold mr-3 tracking-tight text-gray-900 dark:text-neutral-300">asdf</span>
    //           <span className="text-lg text-neutral-600 dark:text-neutral-400">#asdf</span>
    //           </div>
    //         {/* </div> */}
    //         <p className="text-lg dark:text-neutral-300">asdf</p>
    //       </div>
    //       <div className="flex items-center mr-9">
    //         <div className="text-center px-4 py-1.5 text-md hover:cursor-pointer rounded-2xl
    //             font-semibold border-2 border-primary hover:bg-light-raised dark:border-1 dark:border-primary
    //             dark:bg-dark-backdrop dark:hover:bg-dark-raised dark:text-neutral-300 bg-light-backdrop
    //             text-neutral-800">
    //           Join
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* </div> */}
    //   <div className="flex justify-center">
    //     {/* <Outlet /> */}
    //   </div>
    // </div>
  )
}

export default IntroStage;
