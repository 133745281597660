import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { formatTimeDifference } from '../utilities/formatting.js';
import PostMenu from '../components/PostMenu';
import PostPoints from '../components/PostPoints';
import ProgressBarSmall from '../components/ProgressBarSmall';

import LIGHTING from '../scenes/lighting';
import { LightingContext, UserContext } from '../routes/Root';
import { TribuneContext } from '../routes/Tribune';

function isTimestampWithin72Hours(timestamp) {
  const currentTimestamp = new Date();
  const providedTimestamp = new Date(timestamp);

  // Calculate the time difference in milliseconds
  const timeDifferenceMs = currentTimestamp - providedTimestamp;

  // Convert milliseconds to hours
  const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60);

  return timeDifferenceHours < 72;
}

function TribunePostCardBottom(props) {
  const { user } = useContext(UserContext);
  const { lightingTheme } = useContext(LightingContext);
  const { membershipTier } = useContext(TribuneContext);
  const [initialPoints, setInitialPoints] = useState(props.points);
  let repliesImage = '/images/replies-icon.png';
  if (lightingTheme === LIGHTING.DARK) {
    repliesImage = '/images/replies-icon-dark.png';
  }

  let energyClass = 'inline h-5 w-5 mb-1 opacity-60 hover:opacity-100'
  if (props.hasVoted) {
    energyClass = 'inline h-5 w-5 mb-1'
  }

  let voterPoints;
  switch (membershipTier) {
    case 'Newcomer':
      voterPoints = 1;
      break;
    case 'Bronze':
      voterPoints = 5;
      break
    case 'Silver':
      voterPoints = 8;
      break
    case 'Gold':
      voterPoints = 13;
      break
    case 'Platinum':
      voterPoints = 21;
      break
    case 'Diamond':
      voterPoints = 34;
      break
    case 'Onyx':
      voterPoints = 55;
      break
  }

  let energyImg;
  let votingDisabled = true;
  if (props.hasVoted) {
    energyImg = <img src='/images/fire-energy.png' className='w-[35px] h-[35px] cursor-not-allowed'/>;
  } else if (!isTimestampWithin72Hours(props.timestamp)) {
    energyImg = <img src='/images/fire-energy.png' className='w-[35px] h-[35px] opacity-50 cursor-not-allowed'/>;
  } else {
    energyImg = <img src='/images/fire-energy.png' onClick={() => {props.onVoteClick(voterPoints)}} className='w-[35px] h-[35px] opacity-50 hover:opacity-100 cursor-pointer'/>;
    votingDisabled = false;
  }

  return (
    <div className="border-b-[1.5px] md:border-b-0 border-neutral-300 dark:border-neutral-800 pb-4">
      <div className="flex font-semibold text-[15px] pt-2">
        <div className='flex grow text-sm justify-between'>
          <div className='flex gap-x-8'>
            {user && user.id === props.authorId &&
            <PostMenu id={props.id} onDeleteClick={props.onDeleteClick} />
            }
            {/* <div className='flex'>
              <img src={repliesImage} className="inline h-5 w-5 mb-1" />
              <span className='pl-2 text-neutral-600 dark:text-neutral-400'>
                {props.replyCount} {props.replyCount && props.replyCount === 1 ? 'Reply' : 'Replies'}
              </span>
            </div> */}
            {/* <div className='flex'>
              <img src='/images/fire-energy.png' className={energyClass} />
              <span className='pl-2 text-neutral-600 dark:text-neutral-400'>{props.points && props.points} Energy</span>
            </div> */}
          </div>
          {/* <div className='flex'>
            <span className={`text-neutral-700 dark:text-neutral-300`}>{formatTimeDifference(props.timestamp)}</span>
          </div> */}
        </div>
      </div>
      
      <div className='md:hidden flex grow items-center '>
        <div className='flex flex-col w-full mb-0.5'>
          <div className='text-right leading-6 font-semibold dark:text-neutral-300'>
            <PostPoints from={initialPoints} to={props.points} />
          </div>
          <ProgressBarSmall votingExpired={!isTimestampWithin72Hours(props.timestamp)} progressPercentage={props.voteThresholdPercent}/>
        </div>
        <div className='flex-shrink-0 ml-3 text-neutral-200 mr-[-3.5px]'>
          {energyImg}
        </div>
      </div>
    </div>
  );
}

TribunePostCardBottom.propTypes = {
  id: PropTypes.number.isRequired,
  authorId: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  hasVoted: PropTypes.bool.isRequired,
  replyCount: PropTypes.number.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onVoteClick: PropTypes.func.isRequired,
  voteThresholdPercent: PropTypes.number.isRequired,
};

export default TribunePostCardBottom;
