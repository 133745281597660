import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SubscribeToTextModal from '../components/SubscribeToTextModal';

function SubscribeToTextButton(props) {
  const [showModal, setShowModal] = useState(false);

  function onClick() {
    setShowModal(true);
  }

  return (
    <>
      <SubscribeToTextModal showModal={showModal} setShowModal={setShowModal} onComplete={props.onComplete} />
      <div className='flex flex-col w-[22rem] rounded-xl bg-white dark:bg-neutral-900
          border-2 border-neutral-300 dark:border-neutral-700 hover:border-primary cursor-pointer'
              onClick={() => onClick()}>
        <div className='flex justify-between border-b dark:border-neutral-700 py-1.5 px-3'>
          <div className='font-semibold'>Turn on text notifications</div>
          {/* <div className='font-semibold rounded-lg px-2 text-black bg-primary'>+50</div> */}
        </div>
        <div className='flex items-center px-3 pt-1 pb-2'>
          <div className='font-bold rounded-lg px-2 py-1.5 text-black bg-primary mr-3'>+50</div>
          {/* <img className='w-10 h-10 mr-3.5 rounded-lg' src='/images/item-2.png' /> */}
          <div className='font-medium'>Instantly receive +50 entries into this giveaway</div>
        </div>
      </div>
    </>
  );
}

SubscribeToTextButton.propTypes = {
  onComplete: PropTypes.func
}

export default SubscribeToTextButton
