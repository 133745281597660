
import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import RotatingCards from '../components/RotatingCards';
import GiveawayPhoto from '../components/GiveawayPhoto';
import GiveawayAbout from '../components/GiveawayAbout';
import LoginWithXButton from '../components/LoginWithXButton';

function GiveawayLanding(props) {
  if (!props.giveaway) { return <></> }
  return (
    <div className='sm:w-[22rem]'>
      <GiveawayPhoto giveawayId={props.giveaway.id} />
      <GiveawayAbout giveaway={props.giveaway} />
      <div className='flex flex-col items-center justify-between mt-4'>
        {/* <RotatingCards cards={props.giveaway.us_entries} /> */}
        {!props.giveaway.has_ended && <LoginWithXButton /> }
      </div>
    </div>
  )
}

GiveawayLanding.propTypes = {
  giveaway: PropTypes.object,
}

export default GiveawayLanding;