import React, { Fragment } from 'react';
import { Link } from "react-router-dom";

// Given date string like 2023-04-26T15:30:00.000Z, return a readable format
export function formatTimeDifference(dateString) {
  const date = new Date(dateString);
  let now = new Date();
  const timeDifference = now.getTime() - date.getTime();
  const minutes = Math.floor(timeDifference / 60000);
  const hours = Math.floor(timeDifference / 3600000);
  const days = Math.floor(hours / 24);
  if (minutes < 1) {
    return 'Just now';
  } else if (hours < 1) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else {
    const options = { month: 'short', day: 'numeric' };
    const yearOptions = { year: 'numeric', ...options };
    const formattedDate = date.toLocaleDateString(undefined, options);
    const formattedYear = date.toLocaleDateString(undefined, yearOptions).slice(-4);
    const isSameYear = now.getFullYear() === date.getFullYear();

    if (isSameYear) {
    return formattedDate;
    } else {
    return `${formattedDate}, ${formattedYear}`;
    }
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function hashtag(tribuneHandle) {
  return `${capitalizeFirstLetter(tribuneHandle)}Tribune`;
}

export function formatTextWithEntities(text, hashtag, hashtagClass, isLink=false) {
  let words = text.split(' ');

  words = words
    .filter(word => word !== '') // This line ensures that only non-empty words are processed
    .map((word, i) => {
      if (word.toLowerCase() === '#' + hashtag.toLowerCase()) {
        if (isLink) {
          return (
            <Link
              key={i}
              to={'https://twitter.com/hashtag/' + hashtag}
              className={hashtagClass}
            >
              {'#' + hashtag}
            </Link>
          );
        } else {
          return (
            <span
              key={i}
              className={hashtagClass}
            >
              {'#' + hashtag}
            </span>
          );
        }
        
      } else {
        return word;
      }
    });

  const styledText = words.reduce((previous, current, i) => {
    return (
      <>
        {previous}
        <Fragment key={i}> {current}</Fragment>
      </>
    );
  }, <></>);  // providing an empty JSX fragment as initial value
  return styledText;
}
