import React, { useContext } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getSessionUser } from '../utilities/session';

function PrivateRoute({ element }) {
  const { user } = getSessionUser();

  return (
    <Route
      element={user ? element : <Navigate to="/signin" replace />}
    />
  );
}

PrivateRoute.propTypes = {
    element: PropTypes.node.isRequired,
};

export default PrivateRoute;
