import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

function NavigationTabs(props) {
  return (
    <div className="flex justify-center space-x-4">
      {props.tabs.map((item) => (
        <Link 
            key={item.name} 
            to={item.route}
            onClick={item.onClick}
            // {...(option.props || {})}
            className="text-neutral-700 dark:text-neutral-300 rounded-lg px-3 py-2 text-medium font-extrabold"
        >
          {item.name}
        </Link>
      ))}
    </div>
  );
}

NavigationTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      current: PropTypes.bool.isRequired,
    })
  )
};

export default NavigationTabs;