import React, { useState, useRef, useEffect, useContext } from 'react';
import { BrowserView, MobileView, isBrowser, OsTypes, getSelectorsByUserAgent, isAndroid, isIOS} from 'react-device-detect';
import PropTypes from 'prop-types';
import { useLocation, useParams, Link } from 'react-router-dom';
import { UserContext } from './Root';
import { getInventory, getPlayerStats, getGiveawayById, enterGiveaway, getGiveawayLootById } from '../model/tribunes';
import { TribuneContext } from './Tribune';
import { GiveawayContext } from './Giveaway';
import ItemCard from '../components/ItemCard';
import GemSocket from '../components/GemSocket';
import AcknowledgeModal from '../components/AcknowledgeModal';
import SubscribeModal from '../components/SubscribeModal';
import InstallModal from '../components/InstallModal';

import GiveawayLanding from '../components/GiveawayLanding';
import GiveawayEntered from '../components/GiveawayEntered';
import GiveawayAbout from '../components/GiveawayAbout';

import { getGiveawayPhotoS3Bucket } from '../utilities/environment';

import { playSound } from '../utilities/general';
import { profilePictureUrl } from '../utilities/general';

function GiveawayEntry() {
  const { tribune } = useContext(TribuneContext);
  const { giveaway, updateGiveaway } = useContext(GiveawayContext);
  const { user, setUser, signOut} = useContext(UserContext);
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [loot, setLoot] = useState();
  const [playerStats, setPlayerStats] = useState();
  const [loadingEntry, setLoadingEntry] = useState(false);
  const [userEntries, setUserEntries] = useState();
  const [canForge, setCanForge] = useState(false);
  const [numSlots, setNumSlots] = useState(4);

  const [acked, setAcked] = useState(localStorage.getItem('ackEntry'));
  // const [subscribed, setSubscribed] = useState(null);
  const [showAckModal, setShowAckModal] = useState(false);
  // const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [readyToCheck, setReadyToCheck] = useState(false);
  // const [subscribed, setSubscribed] = useState();
  const [declineInstall, setDeclineInstall] = useState(null);
  const [showInstallModal, setShowInstallModal] = useState(false);
  const { isIOS } = getSelectorsByUserAgent(window.navigator.userAgent)
  // const [acked, setAcked] = useState(localStorage.getItem('ackEntry'));

  const animatedBorderClass = canForge ? 'animated-border' : '';
  

  function containsPrismaticFusionCore(itemIds) {
    return itemIds.includes(7);
  }

  function containsEmeraldFusionCore(itemIds) {
    return itemIds.includes(8);
  }

  function isEmerald(itemId) {
    return itemId === 1 || itemId === 12;
  }

  function isColoredGem(itemId) {
    return (itemId >= 1 && itemId <= 4) || (itemId >= 12 && itemId <= 15);
  }

  function forgeItemFound(itemIds) {
    return containsPrismaticFusionCore(itemIds) || containsEmeraldFusionCore(itemIds);
  }

  function checkItemsValid(itemSlots) {
    const items = itemSlots.filter(slot => slot !== null);
    const itemIds = items.map(item => item.item.id);

    if (containsEmeraldFusionCore(itemIds)) {
      const emeraldFilter = itemIds.filter(id => isEmerald(id));
      if (emeraldFilter.length < 3) return { 'valid': false, 'reason': 'You must use 3 Emeralds with the Emerald Fusion Core' };
    }

    if (containsPrismaticFusionCore(itemIds)) {
      const uniqueGems = new Set();
      for (const itemId of itemIds) {
        if (isColoredGem(itemId)) uniqueGems.add(itemId);
      }
      if (uniqueGems.size < 3) return { 'valid': false, 'reason': 'You must use 3 different colored gems with the Prismatic Fusion Core' };
    }
    return { 'valid': true };
  }

  async function updateInventory() {
    const itemsResult = await getInventory(signOut);
    setItems(itemsResult);
  }

  async function updatePlayerStats() {
    const playerStatsResult = await getPlayerStats(190);
    setPlayerStats(playerStatsResult);
  }

  async function updateGiveawayEntries() {
    setUserEntries(giveaway.user_entries);
  }

  async function updateGiveawayLoot() {
    const lootResult = await getGiveawayLootById(giveaway.id);
    setLoot(lootResult);
  }

  const MAX_SLOTS = 5;
  const [selectedItems, setSelectedItems] = useState(Array(MAX_SLOTS).fill(null));

  function replaceWithNext(arr, startIdx) {
    // Loop through the array up to the second-to-last element
    for (let i = startIdx; i < arr.length - 1; i++) {
      arr[i] = arr[i + 1]; // Replace current element with the next one
    }
  
    // Set the last element to null or any desired value
    arr[arr.length - 1] = null;
  
    return arr;
  }

  function hasSpace() {
    for (let i = 0; i < 4; i++) {
      if (selectedItems[i] === null) {
        return true
      }
    }

    for (let i = 4; i < numSlots; i++) {
      if (selectedItems[i] === null) {
        return true
      }
    }
    return false;
  }

  const addToSockets = (itemIndex, item) => {
    let updatedItems = [...selectedItems];
    const itemAlreadySelected = selectedItems.some((selectedItem) => {
      if (selectedItem) {
        return selectedItem?.itemIndex === itemIndex;
      }
      return false; // You might want to handle the case when selectedItem is falsy
    });

    if (itemAlreadySelected) {
      const socketIndex = selectedItems.findIndex((item) => {
        if (item) return item.itemIndex === itemIndex;
        return false;
      });
      
      if (item.id === 10) {
        setNumSlots(4);
        replaceWithNext(updatedItems, socketIndex);
      } else {
        updatedItems[socketIndex] = null;
      }

      setSelectedItems(updatedItems); 
    } else {
      if (item.id === 10) {
        setNumSlots(5);
      }
      if (!hasSpace() && item.id !== 10) {
        console.log('All sockets are filled. Cannot add more gems.');
        return;
      }

      // lootDropSound.play();
      // Item is not selected, add it to the selection
      const firstNullIndex = selectedItems.indexOf(null);
      updatedItems = [...selectedItems];
      updatedItems[firstNullIndex] = { item, itemIndex };
      
      setSelectedItems(updatedItems);
    }
    const itemIds = updatedItems.map(item => item && item.item.id);
    const canForge = forgeItemFound(itemIds);
    setCanForge(canForge);
  };

  function calculateEntries() {
    let itemEntries = 0;
    let abilityEntries = 0;
    for (const item of selectedItems) {
      if (item !== null) {
        if (item.rarity === 'Ability') {
          abilityEntries += Number(item.item.giveaway_entry_adder);
        } else {
          itemEntries += Number(item.item.giveaway_entry_adder);
        }
      } 
    }

    for (const item of selectedItems) {
      if (item !== null) {
        if (item.rarity === 'Ability') {
          abilityEntries *= Number(item.item.giveaway_entry_multiplier);
        } else {
          itemEntries *= Number(item.item.giveaway_entry_multiplier);
        }
      } 
    } 
    return Math.floor(abilityEntries + itemEntries);
  }

  function noneSelected(selectedItems) {
    return (selectedItems[0] === null && selectedItems[1] === null && selectedItems[2] === null && selectedItems[3] === null);
  }

  function moonStoneSelected(selectedItems) {
    const moonStoneSelected = selectedItems.some((selectedItem) => {
      if (selectedItem) {
        return selectedItem?.item.id === 6;
      }
      return false; // You might want to handle the case when selectedItem is falsy
    });
    return moonStoneSelected;
  }

  async function isSubscribed() {
    if ('matchMedia' in window && window.matchMedia('(display-mode: standalone)').matches) {
      // The app is running in standalone mode (as a Progressive Web App)
      let permission = await window.OneSignal.Notifications.permission;
      if (!permission) {
        console.log('Not subbed');
        // show subscribe modal
        return false;
      } else return true;
    } else {
      console.log('Not using as app');
    }
  }

  function isStandaloneApp() {
    if ('matchMedia' in window && window.matchMedia('(display-mode: standalone)').matches) {
      return true;
    } else return false;
  }

  async function handleEnterGiveaway() {
    setReadyToCheck(true);

    if (items.length > 0 && noneSelected(selectedItems)) {
      alert('Select at least one ability or backpack item to enter');
      return
    }

    const hasMoonstone = items.some((item) => {
      return item.id === 6;
    });

    if (hasMoonstone && !moonStoneSelected(selectedItems)) {
      alert('Moonstone must be used. Scroll down to see it');
      return
    }

    const validity = checkItemsValid(selectedItems);
    if (!validity.valid) {
      alert(validity.reason);
      return;
    }

    // if (!giveaway.is_subscribed && subscribed === null) {
    //   setShowSubscribeModal(true);
    //   return;
    // }

    // if (declineInstall === null && playerStats.level >= 3 && !isStandaloneApp() && (isAndroid || isIOS)) {
    //   setShowInstallModal(true);
    //   return;
    // }

    if (!acked) {
      setShowAckModal(true);
      return;
    }
    
    setLoadingEntry(true);

    let entries, loot;
    console.log(giveaway);
    try {
      await enterGiveaway(giveaway.id, selectedItems.filter(
        obj => obj !== null && typeof obj === 'object').map(obj => obj.item.inventory_id),
        isStandaloneApp()
      );
      await updateGiveaway();
      entries = giveaway.entries;
      loot = giveaway.loot;
    } catch (error) {
      setLoadingEntry(false);
      return;
    }

    setLoot(loot);  
    setUserEntries(entries);
    setLoadingEntry(false);
  }

  // useEffect(() => {
  //   if (user && tribune) {
  //     updateInventory();
  //     updatePlayerStats();
  //   }
  //   updateGiveawayEntries();
  //   if (user) updateGiveawayLoot();
  // }, [tribune, user]);

  useEffect(() => {
    if (user && giveaway) {
      updateInventory();
      updatePlayerStats();
      setUserEntries(giveaway.user_entries);
      setLoot(giveaway.loot)
    }
    
  }, [giveaway, user]);

  useEffect(() => {
    if (readyToCheck) {
      handleEnterGiveaway();
    }
  }, [acked]);

  // const welcomeItem = {
  //   id: 6,
  //   name: 'Moonstone',
  //   description: '+10 entries when applied to a giveaway',
  //   rarity: 'Common',
  //   is_consumable: true,
  //   charges_remained: 1
  // };

  if (!giveaway) { return <></> }

  return (
    <div className='flex flex-col items-center'>
      {giveaway && user &&
        <>
          <GiveawayAbout giveaway={giveaway} />
          <AcknowledgeModal
            showModal={showAckModal}
            setAcked={setAcked}
            giveawayId={Number(giveaway.id)}
          />
        </>
      }
      {!user &&
        <GiveawayLanding 
            giveaway={giveaway}
        />
      }
      
      {/* <SubscribeModal
        showModal={showSubscribeModal}
        setSubscribed={setSubscribed}
        tribuneId={Number(tribune.id)}
      /> */}
      {user && giveaway && userEntries > 0 ? (
        <GiveawayEntered
            giveaway={giveaway}
            updateGiveaway={updateGiveaway}
            loot={loot}
            userEntries={userEntries}
        />
      ) : user && giveaway && !giveaway.has_ended && !loadingEntry && (
        <>
        <div className='flex flex-col items-center'>
          <div className='w-screen mb-1 mt-4 px-1'>
              <div className='px-0'>
                <div className='flex justify-center'>
                <div className='relative'>
                <div className='absolute bg-neutral-300 bg-opacity-80 dark:bg-opacity-80 dark:text-neutral-100 z-10 top-[-12px]
                    right-[-2px] dark:bg-neutral-700 rounded-full h-[3.5rem] w-[3.5rem] font-bold flex items-center justify-center'>
                  +{Math.floor(calculateEntries())}
                </div>
                <div className={`dark:bg-black p-3 w-[22rem] dark:border-neutral-700 border-neutral-300 border-[3px] rounded-md ${animatedBorderClass}`}>
              {/* <div className='relative'> */}
                {/* <div> */}
                  {/* <img className='h-[10rem] w-[10rem] mb-2 rounded-lg' src='/images/chest.png' /> */}
                  {/* <div className='font-semibold my-1 pl-1'>Entries: 1</div> */}
                {/* </div> */}
                {numSlots === 4 ? (
                  <div className='flex justify-center gap-x-2.5 mb-2'>
                    <GemSocket item={selectedItems[0] ? selectedItems[0].item : null} slotId={1} small={false}/>
                    <GemSocket item={selectedItems[1] ? selectedItems[1].item : null} slotId={2} small={false}/>
                    <GemSocket item={selectedItems[2] ? selectedItems[2].item : null} slotId={3} small={false}/>
                    <GemSocket item={selectedItems[3] ? selectedItems[3].item : null} slotId={4} small={false}/>
                  </div>
                ) : (
                <div className='flex justify-center gap-x-1.5 mb-2'>
                  <GemSocket item={selectedItems[0] ? selectedItems[0].item : null} slotId={1} small={true} />
                  <GemSocket item={selectedItems[1] ? selectedItems[1].item : null} slotId={2} small={true} />
                  <GemSocket item={selectedItems[2] ? selectedItems[2].item : null} slotId={3} small={true} />
                  <GemSocket item={selectedItems[3] ? selectedItems[3].item : null} slotId={4} small={true} />
                  <GemSocket item={selectedItems[4] ? selectedItems[4].item : null} slotId={5} small={true} />
                </div>
                )}
                <div
                    onClick={handleEnterGiveaway}
                    className='cursor-pointer flex items-center justify-center h-[2rem] font-semibold bg-primary px-3 py-2 rounded-sm'>
                    Enter Giveaway
                  </div>
              {/* </div> */}
            </div>
            </div>
            </div>
            </div>
          </div>
        </div>
        
        {user && !items && <div className='flex justify-center items-center mt-4'></div>}
        {user && playerStats && items && !loadingEntry &&
          <div className='flex items-center w-[22rem] pb-1 px-0.5 justify-between text-xl dark:text-neutral-100 cursor-default border-b-2 dark:border-neutral-800'>
            <img 
                className="h-12 w-12 rounded-full"
                src={profilePictureUrl(user.id)} 
                alt=""
            />
            <div className='flex gap-x-2'>
              <div className='bg-neutral-200 dark:bg-neutral-800 dark:text-neutral-100 py-2 px-3 rounded-lg'>
                Level <span className='font-bold'>{playerStats.level}</span>
              </div>
              <div className='bg-neutral-200 dark:bg-neutral-800 dark:text-neutral-100 py-2 px-3 rounded-lg'>
                XP <span className='font-bold'>{playerStats.xp}</span>
              </div>
            </div>
          </div>
        }
        {user && items && !loadingEntry &&  (
          <div className='flex items-center mt-1 mb-1.5'>
            <img className='h-9 w-9 rounded-full mr-2 border-2 dark:border-0' src='/images/abilities.png' />
            <div className='font-bold text-xl dark:text-neutral-100'>Abilities</div>
          </div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-1.5">
        {user && items && !loadingEntry && items.filter && items.filter(item => item.rarity === 'Ability').map((item, index) => (
          <ItemCard
            key={index}
            index={index}
            item={item}
            addToSockets={addToSockets}
            isSelected={selectedItems.some((selectedItem) => selectedItem?.itemIndex === index)}
          />
        ))}
        </div>
        {user && items && !loadingEntry && (
          <div className='flex items-center mt-1.5 mb-1.5'>
            <img className='h-9 w-9 rounded-full mr-2 border-2 dark:border-0' src='/images/backpack.png' />
            <div className='font-bold text-xl dark:text-neutral-100'>Backpack</div>
          </div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-1.5">
        {items && !loadingEntry && items.filter && items.filter(item => item.rarity !== 'Ability').map((item, index) => (
          <ItemCard
            key={index}
            index={index + items.filter(item => item.rarity === 'Ability').length}
            item={item}
            addToSockets={addToSockets}
            isSelected={selectedItems.some((selectedItem) => selectedItem?.itemIndex === index + items.filter(item => item.rarity === 'Ability').length)}
          />
        ))}
        </div>
        </>
      )}
      {giveaway && giveaway.has_ended === 1 && (
        <div className='mt-2 font-semibold dark:text-neutral-100'>Giveaway has ended</div>
      )}
      {(!giveaway || loadingEntry) && <div className="mt-[10rem] w-16 h-16 border-t-4 border-primary rounded-full animate-spin-slow"></div>}
    </div>
  );
}

GiveawayEntry.propTypes = {
}

export default GiveawayEntry;
