import { React } from 'react'
import { useLocation } from 'react-router-dom';
import VerifyEmailForm from '../components/VerifyEmailForm.js';


function Verify() {
  const { state } = useLocation();
  const { email, username, password } = state;

  return <VerifyEmailForm username={username} password={password} email={email} />;
}

export default Verify;
