import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { UserContext } from '../routes/Root';
import PlayerAction from './PlayerAction';

function SignOutPlayerAction(props) {
  const { signOut } = useContext(UserContext);

  async function onClick() {
    signOut();
  }

  const action = {
    name: 'Sign out', 
    onClick: onClick,
    // route: '/',
    image: '/images/exit-filled.png'
  }

  return (
    <PlayerAction action={action} />
  );
}

SignOutPlayerAction.propTypes = {
};

export default SignOutPlayerAction;
