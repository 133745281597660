const SCENES = {
  INTRO:
    {
      ASLEEP: 'ASLEEP',
      READING: 'READING',
      LOOKING_AROUND: 'LOOKING_AROUND',
      THINKING: 'THINKING',
    
      KILLER_CHOICE: 'KILLER_CHOICE',
      ACHIEVER_CHOICE: 'ACHIEVER_CHOICE',
      EXPLORER_CHOICE: 'EXPLORER_CHOICE',
      SOCIALIZER_CHOICE: 'SOCIALIZER_CHOICE',
    },
};

export default SCENES;
