import axios from 'axios';

import { getAPIDomain } from '../utilities/environment';

const ROOT_URL = 'https://' + getAPIDomain();

export async function getTribunes() {
  const url = ROOT_URL + '/tribunes';
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function getTribuneByHandle(tribuneHandle) {
  const url = ROOT_URL + '/t/' + tribuneHandle;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function getConfig(tribuneId) {
  const url = ROOT_URL + '/tribunes/' + tribuneId + '/config';
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function putConfig(tribuneId, publishThreshold) {
  const url = ROOT_URL + '/tribunes/' + tribuneId + '/config';
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({publishThreshold})
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function insertGiveaway(tribuneId, xPostId) {
  const url = ROOT_URL + '/tribunes/' + tribuneId + '/giveaways';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        xPostId
      }),
      credentials: 'include'
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function enterGiveaway(giveawayId, itemIds, isStandaloneApp) {
  const url = ROOT_URL + '/giveaways/' + giveawayId + '/entry';
  try {
    const response = await axios.post(url, {
        itemIds: itemIds,
        isStandaloneApp
      }, {
        headers: {
          'Content-Type': 'application/json'
      },
      withCredentials: true  // This is equivalent to `credentials: 'include'` in fetch
    });
    return response.data;
  } catch (err) {
    console.error("error: " + err);
    if (err.response.status === 403) {
      alert('Your country is not eligible for this giveaway');
    }
  }
}

export async function subscribeToMessages(tribuneId) {
  const url = ROOT_URL + '/tribunes/' + tribuneId + '/subscribe';
  try {
    await axios.post(url, {}, {
        headers: {
          'Content-Type': 'application/json'
      },
      withCredentials: true  // This is equivalent to `credentials: 'include'` in fetch
    });
  } catch (err) {
    console.error("error: " + err);
  }
}

export async function drawWinner(tribuneId) {
  const url = ROOT_URL + '/tribunes/' + tribuneId + '/giveaways/draw';
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function publishApprovedPosts(tribuneId) {
  const url = ROOT_URL + '/tribunes/' + tribuneId + '/publish';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    if (response.status === 500) {
      throw new Error('500 received');
    }
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}

export async function insertTextNotificationSignUp(giveawayId) {
  const url = ROOT_URL + '/giveaways/' + giveawayId + '/text-signup';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    if (response.status === 500) {
      throw new Error('500 received');
    }
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}

export async function getGiveaways(tribuneId, loggedIn) {
  const url = ROOT_URL + '/tribunes/' + tribuneId + '/giveaways';
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: loggedIn ? 'include' : 'omit',
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function getGiveawayById(giveawayId, loggedIn=false) {
  const url = ROOT_URL + '/giveaways/' + giveawayId;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: loggedIn ? 'include' : 'omit',
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function getPlayerStats() {
  const url = ROOT_URL + '/giveaways/player-stats';
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function getGiveawayLootById(giveawayId) {
  const url = ROOT_URL + '/giveaways/' + giveawayId + '/loot';
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function getGiveawayEntriesById(giveawayId) {
  const url = ROOT_URL + '/giveaways/' + giveawayId + '/entries';
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function getInventory(signOut) {
  const url = ROOT_URL + '/giveaways/inventory';

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
  });
  if (response.status === 401) {
    signOut();
  }
  const responseData = await response.json();
  return responseData;
}

export async function repostPublishedSelfPosts(tribuneId) {
  const url = ROOT_URL + '/tribunes/' + tribuneId + '/repost';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    if (response.status === 500) {
      throw new Error('500 received');
    }
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}
