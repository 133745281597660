import { getAPIDomain } from '../utilities/environment';

const ROOT_URL = 'https://' + getAPIDomain();

export async function getPresignedUrl(bucket, key, contentType) {
  const url = ROOT_URL + '/presigned/generate';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json'
      },
      body: JSON.stringify({ bucket, key, contentType }),
      credentials: 'include'
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}
