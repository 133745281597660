import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../routes/Root';

function Authenticated(props) {
  const { user } = useContext(UserContext);

  return (
    <>
      {!user ? (
        <Navigate to="/signin" replace />
      ) : (
        <Outlet />
      )}
    </>
  );
}

export default Authenticated;