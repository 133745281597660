import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';

const AWS_COGNITO_USER_POOL_ID = 'us-east-1_EfyRXc1j0';
const AWS_COGNITO_CLIENT_ID = '1c836h7gsf5k1hr0q78qujk8pn';

const poolData = {
  UserPoolId: AWS_COGNITO_USER_POOL_ID,
  ClientId: AWS_COGNITO_CLIENT_ID
};
const userPool = new CognitoUserPool(poolData);

export async function signIn(username, password) {
  const authenticationData = {
    Username: username,
    Password: password
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const userData = {
    Username: username,
    Pool: userPool
  };
  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function(result) {
        resolve(result);
      },
      onFailure: function(err) {
        console.error('Error signing in:', err);
        reject(err);
      }
    });
  });
}

export function signOutUser() {
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser) {
    cognitoUser.signOut();
  }
}

// export async function validateSession() {
//   const currentUser = userPool.getCurrentUser();
//   if (currentUser) {
//     currentUser.getSession(function(err, session) {
//       if (err) {
//         console.error('Error getting user session:', err);
//         return false;
//       }

//       // Check if the session is still valid
//       const now = Math.floor(Date.now() / 1000);
//       const expiration = session.getAccessToken().getExpiration();
//       if (expiration < now) {
//         console.log('Token is expired, refreshing...');
//         currentUser.refreshSession(session.getRefreshToken(), function(err, session) {
//           if (err) {
//             console.error('Error refreshing user session:', err);
//             return false;
//           }
//           console.log('Session refreshed:', session);
//         });
//       } else {
//         console.log('Session still valid:', session);
//       }
//     });
//     return true;
//   }
//   console.log('return false');
//   return false;
// }

export async function validateSession() {
  const currentUser = userPool.getCurrentUser();
  if (currentUser) {
    try {
      const session = await new Promise((resolve, reject) => {
        currentUser.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        });
      });

      // Check if the session is still valid
      const now = Math.floor(Date.now() / 1000);
      const expiration = session.getAccessToken().getExpiration();
      if (expiration < now) {
        console.log('Token is expired, refreshing...');
        await new Promise((resolve, reject) => {
          currentUser.refreshSession(session.getRefreshToken(), (err, session) => {
            if (err) {
              reject(err);
            } else {
              console.log('Session refreshed:', session);
              resolve(session);
            }
          });
        });
      } else {
        // console.log('Session still valid:', session);
      }
      return true;
    } catch (err) {
      console.error('Error getting user session:', err);
      return false;
    }
  }
  return false;
}

export async function getSessionUser() {
  const validSession = await validateSession();
  if (validSession === false) {
    console.log("No valid session");
    return null;
  }
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    const session = await new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });

    const userData = await new Promise((resolve, reject) => {
      cognitoUser.getUserData((err, userData) => {
        if (err) {
          reject(err);
        } else {
          resolve(userData);
        }
      });
    });

    return userData;

    // const attributes = await new Promise((resolve, reject) => {
    //   cognitoUser.getUserAttributes((err, attributes) => {
    //     if (err) {
    //       reject(err);
    //     } else {
    //       resolve(attributes);
    //     }
    //   });
    // });

    // return attributes.;
    // cognitoUser.getSession(function(err, session) {
    //   if (err) {
    //     console.error('Error getting session:', err);
    //   } else {
    //     console.log('Session:', session);
    //     cognitoUser.getUserData(function(err, userData) {
    //       if (err) {
    //         console.error('Error getting user data:', err);
    //       } else {
    //         console.log('User data:', userData);
    //       }
    //     });
    //   }
    // });
  }
}
