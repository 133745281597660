import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { profilePictureUrl } from '../utilities/general';

function ProximityLeaderboard(props) {
  let render;
  if (props.ranking.ranking == 1) {
    render = (
      <div className='flex items-center w-[20rem] ml-4 p-3 rounded-xl mt-2 bg-white dark:bg-neutral-900 border-2 dark:border-neutral-800'>
        <img className='h-[3.5rem] w-[3.5rem] rounded-full mr-2' src={profilePictureUrl(props.ranking.id)} />
        <div className='flex flex-col font-semibold'>
          
          <div>1st place in entries so far!</div>
        </div>
      </div>
    )
  } else if (!props.ranking.below_id)  {
    render = (
      <div className='flex flex-col gap-y-2 my-2'>
        <div className='flex items-center gap-x-2 opacity-70'>
          <div className='w-[7rem] text-right font-bold'>{props.ranking.above_ranking}.</div>
          <img className='h-8 w-8 rounded-full' src={profilePictureUrl(props.ranking.above_id)} />
          <div className='font-medium'>{props.ranking.above_num_entries} entries</div>
        </div>
        <div className='flex items-center gap-x-2'>
          <div className='w-[7rem] text-right font-bold'>{props.ranking.ranking}.</div>
          <img className='h-8 w-8 rounded-full' src={profilePictureUrl(props.ranking.id)} />
          <div className='font-bold dark:text-primary'>{props.ranking.num_entries} entries</div>
        </div>
      </div>
    )
  } else {
    render = (
      <div className='flex flex-col gap-y-2 my-2'>
        <div className='flex items-center gap-x-2 opacity-70'>
          <div className='w-[7rem] text-right font-bold'>{props.ranking.above_ranking}.</div>
          <img className='h-8 w-8 rounded-full' src={profilePictureUrl(props.ranking.above_id)} />
          <div className='font-medium'>{props.ranking.above_num_entries} entries</div>
        </div>
        <div className='flex items-center gap-x-2'>
          <div className='w-[7rem] text-right font-bold'>{props.ranking.ranking}.</div>
          <img className='h-8 w-8 rounded-full' src={profilePictureUrl(props.ranking.id)} />
          <div className='font-bold dark:text-primary'>{props.ranking.num_entries} entries</div>
        </div>
        <div className='flex items-center gap-x-2 opacity-70'>
          <div className='w-[7rem] text-right font-bold'>{props.ranking.below_ranking}.</div>
          <img className='h-8 w-8 rounded-full' src={profilePictureUrl(props.ranking.below_id)} />
          <div className='font-medium'>{props.ranking.below_num_entries} entries</div>
        </div>
      </div>

      
      // <div className="grid grid-cols-3 gap-4 auto-rows-max auto-cols-max">
      //   <span className='font-bold'>{props.ranking.above_ranking}.</span>
      //   <img className='h-8 w-8 rounded-full' src={profilePictureUrl(props.ranking.above_id)} />
      //   <span className='font-medium'>{props.ranking.above_num_entries} entries</span>

      //   <span className="bg-gray-500">4</span>
      //   <span className="bg-gray-600 ">5</span>
      //   <span className="bg-gray-700">6</span>

      //   <span className="bg-gray-800">7</span>
      //   <span className="bg-gray-900 ">8</span>
      //   <span className="bg-gray-1000 ">9</span>
      // </div>

    )
  }


  // const [clicked, setClicked] = useState(false);
  // const loadingAnimation = <div className="w-14 h-14 border-t-4 border-primary rounded-full animate-spin-slow"></div>;
  // const button = (
  //     <button onClick={() => {
  //           setClicked(true);
  //           goToTwitterAuth('user')}} 
  //         className='text-lg text-center w-[22rem] font-medium bg-primary py-3 rounded-xl mt-5'>
  //       Login with X to Enter Giveaway
  //     </button>
  // );
  // const render = clicked ? loadingAnimation : button;
  return render;
}

ProximityLeaderboard.propTypes = {
  ranking: PropTypes.object
}

export default ProximityLeaderboard
