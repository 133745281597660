import { getAPIDomain, isProdEnv } from '../utilities/environment';

const ROOT_URL = 'https://' + getAPIDomain();

export async function requestCode(phoneNumber) {
  const url = ROOT_URL + '/phone/send'
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ phoneNumber }),
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  });
  const responseData = await response.json();
  if (response.status === 500) {
    throw Error(responseData.error);
  }
  return responseData;
}

export async function verifyCode(phoneNumber, code) {
  const url = ROOT_URL + '/phone/verify'
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ phoneNumber, code }),
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  });
  const responseData = await response.json();
  if (response.status === 500) {
    throw Error(responseData.error);
  }
  return responseData;
}
