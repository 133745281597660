import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// import { Outlet, useParams } from 'react-router-dom';
import ReplyCardHeading from './ReplyCardHeading.js';
import PostCardContent from './PostCardContent.js';
import PostCardBottom from './PostCardBottom.js';
import ReplyCardSideBar from './ReplyCardSideBar.js';
import { votePost } from '../model/posts.js';

import { UserContext } from '../routes/Root';

function ReplyCard(props) {
  const [hasVoted, setHasVoted] = useState(Boolean(props.post.has_voted));
  const [points, setPoints] = useState(props.post.points);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setPoints(props.post.points);
  }, [props.post.points]);

  async function handleVote() {
    // DUPLICATE FUNCTION, replace
    try {
      setHasVoted(true);
      const post = await votePost(props.post.id, user.id);
      setPoints(parseInt(post.points));
    } catch(error) {
      console.error("Error voting on post\n", error);
    }
  }

  async function handleDelete() {
    console.log('delete');
  }

  const postComponents = (
    <>
      <div className="flex flex-col flex-grow ml-12 mt-4">
        <div className="flex">
          <ReplyCardSideBar 
              points={points}
              reply={props.reply}
              hasVoted={hasVoted}
              onVoteClick={() => handleVote()}
              postId={Number(props.post.id)}
              authorId={Number(props.post.author_id)}
              voteThresholdPercent={(points / (props.post.publish_points_threshold + 1)) * 100} // GIVE A REAL VALUE
              authorProfilePictureUrl={props.post.author_profile_picture_url}
          />
          <div className="flex flex-col rounded-r flex-grow overflow-hidden bg-light-backdrop dark:bg-dark-backdrop">
            <ReplyCardHeading
                id={Number(props.post.id)}
                authorId={Number(props.post.author_id)}
                authorHandle={props.post.author_handle}
                authorDisplayName={props.post.author_display_name}
                pictureUrl={props.post.author_profile_picture_url}
                timestamp={props.post.created_at}
                tribuneHandle={props.post.tribune_handle}
                outsideTribune={false}
                onDeleteClick={handleDelete}
            />
            <PostCardContent
                id={props.post.id}
                text={props.post.body}
                reply={props.reply}
            />
            <PostCardBottom
                id={props.post.id}
                topLevel={false}
                points={points}
                replyCount={props.post.reply_count}
                hasVoted={hasVoted}
                onVoteClick={handleVote}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {postComponents}
    </>
  );
}

ReplyCard.propTypes = {
  post: PropTypes.object.isRequired,
  reply: PropTypes.bool,
  outsideTribune: PropTypes.bool,
  feed: PropTypes.bool
};

export default ReplyCard;
