import { React, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Menu } from '@headlessui/react';
import { profilePictureUrl } from '../utilities/general';

import LIGHTING from '../scenes/lighting';
import { PlayerContext, UserContext, SceneContext, LightingContext } from '../routes/Root';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function GemSocket(props) {
  const { playerText, playerActions, playerOnClick } = useContext(PlayerContext);
  const { user } = useContext(UserContext);
  const { sceneName, sceneState } = useContext(SceneContext);
  const { lightingTheme } = useContext(LightingContext);

  const [outsiderImage, setOutsiderImage] = useState(lightingTheme === LIGHTING.DARK ? '/images/unknown.png' : '/images/unknown-dark.png')

  function handleClick() {
    // event.preventDefault();
    playerOnClick && playerOnClick();
  }

  const sizeClass = props.small ? 'w-[3.73rem] h-[3.73rem]' : 'w-[4.55rem] h-[4.55rem]';
  const sizeClassEmpty = props.small ? 'w-[3.73rem] h-[3.73rem]' : 'w-[4.55rem] h-[4.55rem]';
  
  return (
    <div className='flex flex-col items-center]'>
      {!props.item ? (
        <div className={`rounded-md dark:border-neutral-700 border-neutral-300 border-2 bg-white dark:bg-neutral-900 ${sizeClassEmpty}`}></div>
      ) : (
        <img src={`/images/item-${props.item.id}.png`} className={`rounded-md dark:border-neutral-700 border-neutral-200 border-2 ${sizeClass}`} />
      )}
      {/* <div className='dark:text-neutral-300 text-neutral-800 mb-2 font-semibold'>Item Slot {props.slotId}</div> */}
    </div>
  );
}

GemSocket.propTypes = {
  item: PropTypes.object,
  slotId: PropTypes.number,
  small: PropTypes.bool
};

GemSocket.defaultProps = {
  small: false,
};

export default GemSocket;
