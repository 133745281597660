import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SocialLinksCard from '../components/SocialLinksCard';
import { profilePictureUrl } from '../utilities/general';

function Profile() {
  const { userHandle } = useParams();
  const [ user, setUser ] = useState(null);
  const [twitterAccount, setTwitterAccount] = useState();
  const [facebookAccount, setFacebookAccount] = useState();

  async function getUser() {
    // const user = await getUserByHandle(userHandle);
    setUser(user);
  }

  useEffect(() => {
    getUser();
  }, []);
  
  return (
    <div className='flex flex-col'>
      <div className="flex justify-center bg-light-backdrop border-neutral-300 dark:bg-dark-backdrop
            border-b dark:border-neutral-800 py-2">
        <div className="flex w-[40rem]">
          <div className="flex items-center">
            {user && <img 
                className="h-[5rem] w-[5rem] rounded-full
                    border-2 border-neutral-600 dark:border-0" 
                src={profilePictureUrl(user.id)} alt="" /> }
          </div>
          <div className="flex flex-col flex-grow justify-center pl-3">
            <div className="">
              {user && <span className="text-3xl font-bold mr-3 tracking-tight text-gray-900 dark:text-neutral-300">{user.display_name}</span>}
              {user && <span className="text-lg text-neutral-600 dark:text-neutral-400">t/{user.handle}</span>}
            </div>
            {user && <p className="text-lg dark:text-neutral-300">{user.about}</p>}
          </div>
          <div className="flex items-center mr-9">
            {/* <div className="text-center px-4 py-1.5 text-md hover:cursor-pointer rounded-2xl
                font-semibold border-2 border-primary hover:bg-light-raised dark:border-1 dark:border-primary
                dark:bg-dark-backdrop dark:hover:bg-dark-raised dark:text-neutral-300 bg-light-backdrop
                text-neutral-800">
              Join
            </div> */}
          </div>
        </div>
      </div>
      <div className='flex justify-center mt-3'>
        <div className='flex'>
          <div className='w-[22rem]'>
            {/* left col */}
          </div>
          <div className='w-[40rem]'>
            {/* center col */}
          </div>
          <div className='w-[22rem]'>
            <SocialLinksCard user={user && user} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
