import { getAPIDomain } from '../utilities/environment';

const ROOT_URL = 'https://' + getAPIDomain();

export async function getPosts() {
    const url = ROOT_URL + '/posts';
    try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const responseData = await response.json();
        return responseData;
    } catch (err) {
        console.error("error: " + err);
    }
}

export async function getPostById(postId, userId = null) {
  let url = ROOT_URL + '/posts/' + postId;
  if (userId) {
    url += '?userId=' + userId;
  }
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
      console.error("error: " + err);
  }
}

export async function getPostingStats(tribuneHandle) {
  let url = ROOT_URL + '/posts/t/' + tribuneHandle + '/stats';
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
      console.error("error: " + err);
  }
}

// Had to change from a GET request to included encrypted userId in body (get requests don't allow body)
// User authorization solves this, right now we just have app-level auth
export async function getPostsByTribuneHandleForUser(tribuneHandle, userId, page = 1) {
  const url = ROOT_URL + '/posts/t/' + tribuneHandle + '?page=' + page + '&userId=' + userId;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function getModerationFeed(tribuneHandle, page = 1) {
  const url = ROOT_URL + '/posts/t/' + tribuneHandle + '/moderate' + '?page=' + page;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function decidePost(postId, approve, reason=null) {
  const url = ROOT_URL + '/posts/' + postId + '/moderate';
  try {
    await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        approve,
        reason
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
  } catch (err) {
    console.log("error: " + err);
  }
}

// Example post:
// {
//     title: '',
//     body: 'my first tribune post',
//     user_id: 1
// }
export async function createTribunePost(post, tribuneHandle) {
  // To consider:
  // Do all posts (even replies) belong to tribunes?
  // Can poster post a reply to a tribune's post from inside another tribune?
  try {
    const url = ROOT_URL + '/posts/t/' + tribuneHandle;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(post),
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    if (response.status === 500) {
      throw Error(response);
    }
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}


// Example post:
// {
//     title: '',
//     body: 'my first tribune post',
//     user_id: 1,
//     scheduled_date
// }
export async function createPost(post) {
  try {
    const url = ROOT_URL + '/posts';
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(post),
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    if (response.status === 500) {
      throw Error(response);
    }
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function insertPostImage(imageId, postId, imageUrl, imageExtension) {
  const url = ROOT_URL + '/posts/' + postId + '/images';
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      id: imageId,
      extension: imageExtension,
      url: imageUrl
    }),
    headers: {
      'Content-Type': 'application/json'
    }, 
    credentials: 'include'
  });
  await response;
  if (response.status === 500) {
    throw Error(response);
  }
}

// Doesn't remove row, just removes content and marks as deleted
export async function deletePost(postId) {
  const url = ROOT_URL + '/posts/' + postId;
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    console.log("error: " + err);
  }
}

export async function votePost(postId) {
  const url = ROOT_URL + '/posts/' + postId + '/vote/';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    if (response.status === 500) {
      throw new Error('500 received');
    }
    const responseData = await response.json();
    return responseData;
  } catch (err) {
      console.log("error: " + err);
      throw err;
  }
}

export async function publishApprovedPostToX(postId) {
  const url = ROOT_URL + '/posts/' + postId + '/publish-approved';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    if (response.status === 500) {
      throw new Error('500 received');
    }
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}

export async function publishPostToX(postId) {
  const url = ROOT_URL + '/posts/' + postId + '/publish';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    if (response.status === 500) {
      throw new Error('500 received');
    }
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}

export async function repostPost(postId) {
  const url = ROOT_URL + '/posts/' + postId + '/vote/';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    if (response.status === 500) {
      throw new Error('500 received');
    }
  } catch (err) {
    console.log("error: " + err);
    throw err;
  }
}
