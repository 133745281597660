import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { profilePictureUrl } from '../utilities/general';

import FacebookFriend from './FacebookFriend';

function FacebookFriendsCard(props) {
  // let avatarClass = 'h-[60px] w-[60px] rounded-full';
  // let ARCHETYPE = '';
  // if (props.entity.type === 'user') {
  //   ARCHETYPE = 'explorer';
  // }
  // if (ARCHETYPE === 'killer') {
  //   avatarClass += ' border-[3.5px] border-red-600 dark:border-red-600';
  // } else if (ARCHETYPE === 'achiever') {
  //   avatarClass += ' border-[3.5px] border-amber-600 dark:border-amber-600';
  // } else if (ARCHETYPE === 'explorer') {
  //   avatarClass += ' border-[3.5px] border-sky-600 dark:border-sky-600';
  // } else if (ARCHETYPE === 'socializer') {
  //   avatarClass += ' border-[3.5px] border-purple-800 dark:border-purple-800';
  // } else {
  //   avatarClass += ' border-[1.5px] border-neutral-700';
  //   // avatarClass += ' border-neutral-700 dark:border-neutral-700';
  // }
  return (
    // <div className="w-[18rem] border-2 dark:text-neutral-200 bg-light-raised dark:bg-dark-raised 
    // border-neutral-300 dark:border-neutral-800 rounded-lg px-5 pt-3 pb-3">

    //   <div className='mb-3 text-xl font-bold'>
    //     Suggested
    //   </div>
    //   {props.friends && <FacebookFriend friend={props.friends[0]} />}
    //   !!! Eventually include more than 1 friend to suggest !!!

    //   {props.friends && props.friends.map(friend => (
    //     <FacebookFriend key={friend.id} friend={friend} />
    //   ))}
    // </div>
    <></>
  );
}

FacebookFriendsCard.propTypes = {
  // entity: PropTypes.object.isRequired,
  friends: PropTypes.array.isRequired
}

export default FacebookFriendsCard;