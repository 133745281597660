import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import LIGHTING from '../scenes/lighting';
import { LightingContext } from '../routes/Root';

function PostCardBottom(props) {
  const { lightingTheme } = useContext(LightingContext);
  // const [points, setPoints] = useState(props.points);

  let repliesImage = '/images/replies-icon.png';
  if (lightingTheme === LIGHTING.DARK) {
    repliesImage = '/images/replies-icon-dark.png';
  }

  let energyClass = 'inline h-5 w-5 mb-1 opacity-60 hover:opacity-100 cursor-pointer'
  if (props.hasVoted) {
    energyClass = 'inline h-5 w-5 mb-1 cursor-not-allowed'
  }

  let replyText = 'Replies';
  let energyText = 'Energy';
  if (!props.topLevel) {
    replyText = '';
    energyText = '';
  } else if (props.replyCount && props.replyCount === 1) {
    replyText = 'Reply'
  }

  let replyCount = '';
  if (props.replyCount && props.replyCount > 0) {
    replyCount = String(props.replyCount);
  }
  return (
    <>
    <div className="flex inline-block grow font-semibold text-[15px] mt-2.5 pr-3">
    <div className='flex self-end text-sm'>
        <div className='w-[120px]'>
          <img src={repliesImage} className="inline h-5 w-5 mb-1" />
          <span className='pl-2 text-neutral-600 dark:text-neutral-400'>
            {/* Only show "replies" for top of post route, not on the replies themselves */}
            {replyCount} {replyText}
          </span>
        </div>
        <div>
          <img src='/images/fire-energy.png' className={energyClass} />
          <span className='pl-2 text-neutral-600 dark:text-neutral-400'>{props.points && props.points} {energyText}</span>
        </div>
    </div>
    </div>
    </>
  );
}

PostCardBottom.propTypes = {
  id: PropTypes.number.isRequired,
  topLevel: PropTypes.bool,
  points: PropTypes.number.isRequired,
  replyCount: PropTypes.number.isRequired,
  onVoteClick: PropTypes.func.isRequired,
  hasVoted: PropTypes.bool.isRequired,
};

export default PostCardBottom;
