import React, { Component } from 'react';
import { Navigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { signIn } from '../utilities/session.js';
import { LockClosedIcon } from '@heroicons/react/20/solid'
import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: 'us-east-1'
});
const cognito = new AWS.CognitoIdentityServiceProvider();

class VerifyEmailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
      verifySucccess: false
    };

    this.confirmSignUp = this.confirmSignUp.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  async confirmSignUp(email, confirmationCode) {
    try {
      const result = await cognito.confirmSignUp({
        ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
        Username: email,
        ConfirmationCode: confirmationCode
      }).promise();
      console.log('Confirm sign up successful:', result);
      return result;
    } catch (error) {
      console.error('Error confirming sign up:', error);
    }
  }
  
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();

    const form = event.target;
    const code = event.target.code.value
    
    if (form.checkValidity()) {
      try {
        await this.confirmSignUp(this.props.email, code);
        await signIn(this.props.username, this.props.password);
        this.setState({ verifySuccess: true });
      } catch (error) {
        console.log("Error signing up / signing in:", error);
      }
    } else {
      form.reportValidity();
    }
  }

  render() {
    if (this.state.verifySuccess) {
      return <Navigate to="/AddUserDetails" />;
    } else {
      return (
        <>
        <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="w-full max-w-md space-y-8">
            <div>
              <img
                className="mx-auto h-12 w-auto"
                src="/images/icon.png"
                alt="Tribune"
              />
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-neutral-700 dark:text-neutral-200">
                You&apos;re almost in...
              </h2>
              <p className="mt-2 text-center text-neutral-700 dark:text-neutral-200">
                A verification code was sent to <b>{ this.props.email }</b>
              </p>
            </div>
            <form className="mt-8 space-y-6" action="#" onSubmit={this.handleSubmit}>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="-space-y-px rounded-md shadow-sm">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Verification code
                  </label>
                  <input
                    id="code"
                    name="code"
                    type="text"
                    autoComplete="off"
                    required
                    className="relative block w-full appearance-none rounded-none rounded-t-md border border-neutral-300
                        px-3 py-2 text-neutral-900 placeholder-neutral-500 focus:z-10 focus:border-green-500
                        focus:outline-none focus:ring-green-500 sm:text-sm"
                    placeholder="Verification code"
                    onChange={this.handleChange}
                  />
                </div>
              {/* </div>
              {this.state.errors.length > 0 && (
                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                  <ul>
                    {this.state.errors.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div> */}
                <button
                  type="submit"
                  className="group relative flex w-full justify-center rounded-b-md border border-transparent
                      bg-green-400 py-2 px-4 text-sm font-medium text-white hover:bg-green-500 focus:outline-none
                      focus:ring-2 focus:ring-green-300 focus:ring-offset-2"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockClosedIcon className="h-5 w-5 text-green-300 group-hover:text-green-400" aria-hidden="true" />
                  </span>
                  Verify account
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
      );
    }
  }
}

VerifyEmailForm.propTypes = {
  email: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired
};

export default VerifyEmailForm;
