import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { getAPIDomain, getWebDomain, isLocalEnv } from '../utilities/environment';

// const TWITTER_SCOPE = ['tweet.read', 'tweet.write', 'tweet.moderate.write', 'users.read', 'follows.read',
//     'follows.write', 'offline.access', 'space.read', 'mute.read', 'mute.write', 'like.read', 'like.write',
//     'list.read', 'list.write', 'block.read', 'block.write', 'bookmark.read', 'bookmark.write'].join('%20');

const TWITTER_AUTH_URL = 'https://' + getAPIDomain() + '/twitter/1/auth';
const TWITTER_AUTH_URL_TRIBUNE = 'https://' + getAPIDomain() + '/twitter/1/auth-tribune';
const PORT = isLocalEnv() ? ':3000' : '';
const redirectUrl = 'https://' + getWebDomain() + PORT +  '/signin';

export async function getTwitterAuthUrlV1(entityType) {
  let url = TWITTER_AUTH_URL;
  let includeCredentials = false;
  if (entityType === 'tribune') {
    url = TWITTER_AUTH_URL_TRIBUNE;
    includeCredentials = true;
  }
  try {
    const { data } = await axios.get(url, { params: { redirectUrl }, withCredentials: includeCredentials});
    return data.url;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function goToTwitterAuth(entityType) {
  localStorage.setItem('context', window.location.pathname);
  const authUrl = await getTwitterAuthUrlV1(entityType);
  window.location.href=authUrl;
}

/* 
Using V1 to init Twitter users for now
V1 creds work for V2 endpoints
V1 creds are necessary for media upload
Twitter may change the API at some point
*/

// export async function connectTwitter(code) {
//   const verifier = '8KxxO-RPl0bLSxX5AWwgdiFbMnry_VOKzFeIlVA7NoA';
//   const requestBody = {
//     code: code,
//     codeVerifier: verifier,
//     redirectUri: redirectUrl,
//     entityType: 'user'
//   };

//   const response = await fetch(TWITTER_AUTH_URL, {
//     method: 'POST',
//     credentials: 'include',
//     mode: 'cors',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(requestBody)
//   });
//   const responseData = await response.json();
//   return responseData;
// }

export async function connectTwitterV1(tempToken) {
  const url = 'https://' + getAPIDomain() + '/auth/1/twitter';

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({tempToken})
  });
  const responseData = await response.json();
  return responseData;
}
