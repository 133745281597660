import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import { uploadFileToS3 } from '../utilities/S3FileUpload';

import { profilePictureUrl } from '../utilities/general';
import { UserContext, LightingContext } from '../routes/Root';
import { TribuneContext } from '../routes/Tribune';
import Feed from '../components/Feed.js';
import TokensCard from '../components/TokensCard.js';
import { hashtag } from '../utilities/formatting';

import PopUpDialog from '../components/PopUpDialog.js';
import PostModal from '../components/PostModal.js';
import LandingModal from '../components/LandingModal.js';
import LIGHTING from '../scenes/lighting';

import { getPostsByTribuneHandleForUser, createTribunePost, insertPostImage } from '../model/posts.js';
import { getBalances } from '../model/tokens.js';
import { goToTwitterAuth } from '../utilities/twitter';

function ForumChannel() {
  const { user, setShowSignUpModal } = useContext(UserContext);
  const { tribune, membershipTier, setMembershipTier } = useContext(TribuneContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [posts, setPosts] = useState([]);
  const [ showModal, setShowModal ] = useState(false);
  const { lightingTheme } = useContext(LightingContext);
  const [imageExtension, setImageExtension] = useState();
  const [imageFile, setImageFile] = useState();
  const [balanceData, setBalanceData] = useState();
  const [progressWidth, setProgressWidth] = useState();
  const [pointsPower, setPointsPower] = useState();

  const [outsiderImage, setOutsiderImage] = useState(lightingTheme === LIGHTING.DARK ? '/images/unknown.png' : '/images/unknown-dark.png')

  const textareaRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);

  async function fetchPosts(nextPage) {
    if (!tribune) {
      return;
    }

    const result = await getPostsByTribuneHandleForUser(tribune.handle, user ? user.id: null, nextPage);
    if (user) {
      const balancesResult = await getBalances();
      if (tribune.id in balancesResult) {
        setBalanceData(balancesResult[tribune.id]);
        setMembershipTier(balancesResult[tribune.id].membership_tier);
      } else {
        setBalanceData({balance: 0, membership_tier: 'Newcomer', percentile_of_tier: 0, percentile: 0});
        setMembershipTier('Newcomer');
      }
    }
    setPosts([...posts, ...result.posts]);
    return result;
  }

  function toggleShowModal() {
    if (showModal) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  }

  function handleCloseDialog() {
    setIsDialogOpen(false);
  }

  async function handlePost(event, type) {
    event.preventDefault();
    const text = textareaRef.current.value;
    toggleShowModal();
    const tempPost = {
      'body': text,
      'author_id': user.id,
      'author_handle': user.handle,
      'author_display_name': user.display_name,
      'tribune_twitter_handle': tribune.twitter_handle,
      'tribune_twitter_display_name': tribune.twitter_display_name,
      'tribune_id': tribune.id,
      'created_at': new Date().toISOString(),
      'points': 1,
      'publish_points_threshold': tribune.publish_threshold,
      'type': type,
      'has_voted': true
    }
    if (imagePreview) {
      tempPost.media_url = imagePreview;
    }
    setPosts(posts => [tempPost, ...posts]);
    try {
      // User type posts: User tweets, then Tribune retweets
      // Tribune type posts: Tribune tweets and credits author in comment
      const resp = await createTribunePost(
          { 
            'title': '',
            'body': text,
            'authorId': user.id,
            'type': type,
          }, 
          tribune.handle
      );
      const newPost = resp.post;
      
      // Upload to S3
      let imageUrl, imageId;
      if (imagePreview) {
        imageId = uuidv4();
        const result = await uploadFileToS3(imageId, imageFile, imageExtension);
        imageUrl = result.url.split("?")[0];
        insertPostImage(imageId, resp.result.insertId, imageUrl, imageExtension);
        newPost.media_url = imagePreview;
      }
    } catch(error) {
      // setDialogText('You have reached the posting limit.\n Limits:\n* 2 times per 10 minutes\n* 5 times per hours\n* 20 times per 24 hours')
      // setIsDialogOpen(true);
      console.error('Error: ', error.message, error);
      return;
    }
  }

  useEffect(() => {
    fetchPosts();
  }, [tribune, user]);

  useEffect(() => {
    if (balanceData && 'percentile_of_tier' in balanceData) {
      setProgressWidth(balanceData.percentile_of_tier.toString() + '%');
    
      switch(balanceData.membership_tier) {
        case 'Newcomer':
          setPointsPower(1);
          break;
        case 'Bronze':
          setPointsPower(5);
          break;
        case 'Silver':
          setPointsPower(8);
          break;
        case 'Gold':
          setPointsPower(13);
          break;
        case 'Platinum':
          setPointsPower(21);
          break;
        case 'Diamond':
          setPointsPower(34);
          break;
        case 'Onyx':
          setPointsPower(55);
          break;
      }
    }
  }, [balanceData]);

  return (
    <div className='flex w-full'>
      <PopUpDialog isOpen={isDialogOpen} handleClose={handleCloseDialog} text={dialogText} />
      {user && tribune &&
      <PostModal
          isReply={false}
          twitterHandle={tribune && tribune.twitter_handle}
          twitterDisplayName={tribune && tribune.twitter_display_name}
          tribuneId={tribune && tribune.id}
          hashtag={tribune && hashtag(tribune.handle)}
          showModal={showModal}
          toggleShowModal={toggleShowModal}
          handlePost={handlePost}
          textareaRef={textareaRef}
          setImageFile={setImageFile}
          setImageExtension={setImageExtension}
          imagePreview={imagePreview}
          setImagePreview={setImagePreview}
      />
      }
      <div className="flex flex-col md:mx-4">
        {user &&
        <div className="flex xl:hidden mx-4 sm:mx-3 mb-2">
          <img 
              className="h-12 w-12 rounded-full" 
              src={user ? profilePictureUrl(user.id) : outsiderImage} 
              alt="" 
          />
          <div className='flex flex-col grow justify-center leading-4 ml-2'>
            <div className='items-center mb-1 text-lg'>
              <div className='font-bold text-neutral-800 dark:text-neutral-300 text-xl leading-5'>
                {membershipTier}
              </div>
              
            </div>
            <div className='w-full rounded-xl border-2 border-neutral-400 dark:border-neutral-700 h-2'>
              <div className='bg-neutral-800 dark:bg-neutral-300 h-1 rounded-xl' style={{ width: progressWidth }}>
              </div>
            </div>
            <div className='flex xs:hidden'>
              <div className='font-medium text-neutral-800 dark:text-neutral-300 text-sm'>
                Submissions: {balanceData && Math.round(balanceData.balance)}
              </div>
              <div className='font-semibold ml-3 text-neutral-800 dark:text-neutral-300 text-sm'>
                Power: +{pointsPower}
              </div>
            </div>
            <div className='hidden xs:flex'>
              <div className='font-medium text-neutral-800 dark:text-neutral-300 text-sm'>
                Approved Submissions: {balanceData && Math.round(balanceData.balance)}
              </div>
              <div className='font-semibold ml-3 text-neutral-800 dark:text-neutral-300 text-sm'>
                Upvote Power: +{pointsPower}
              </div>
            </div>
          </div>
         
        </div>
        
        }
        <div className="flex mb-1 ">
          <div className="hidden xl:block flex w-[62px] justify-center pt-1 ml-2">
            <img className="h-12 w-12 rounded-full" src={user ? profilePictureUrl(user.id) : outsiderImage} alt="" />
          </div>
          {/* <div className='flex items-center justify-center'> */}

          
            <div
                className="xl:w-[41.5rem] mx-3 mb-2 sm:mx-0 py-1 appearance-none rounded-lg focus:outline-none border-[1.5px]
                    border-neutral-400 text-neutral-700 dark:border-neutral-700 bg-light-backdrop
                    text-lg resize-none p-3 dark:text-neutral-300 dark:bg-dark-backdrop hover:cursor-pointer" 
                onClick={() => {user ? toggleShowModal() : goToTwitterAuth()}}
            >
              {/* Even the smallest ember can start a great fire. Ignite us with your words */}
              Say something and be heard. If the tribune enjoys your post, it will be reviewed and posted to X for you
            </div>
          {/* </div> */}
        </div>
        <Feed
          posts={posts}
          setPosts={setPosts}
          fetchPosts={fetchPosts}
          tribuneHashtag={tribune && hashtag(tribune.handle)}
        />
      </div>
      <div className='hidden xl:block w-[22rem]'>
      {user && balanceData && 
        <div className='sticky top-20 w-[19rem] bg-white dark:bg-black rounded-lg border-2 border-neutral-200 dark:border-neutral-800'>
          <div className='ml-3 mt-2 mb-1 font-bold text-neutral-900 dark:text-neutral-300'>{user.display_name}</div>
          <TokensCard balanceData={balanceData} pointsPower={pointsPower} tribuneName={tribune.display_name}  />
        </div>
      } 
      </div>
    </div>
  );
}

ForumChannel.propTypes = {
  // tribune: PropTypes.object.isRequired,
}

export default ForumChannel;
