import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// import { Outlet, useParams } from 'react-router-dom';
import TribunePostCardHeading from './TribunePostCardHeading.js';
import TribunePostCardContent from './TribunePostCardContent.js';
import TribunePostCardBottom from './TribunePostCardBottom.js';
import TribunePostCardSideBar from './TribunePostCardSideBar.js';
import { votePost } from '../model/posts.js';

import { UserContext } from '../routes/Root';

function TribunePostCard(props) {
  const [hasVoted, setHasVoted] = useState(Boolean(props.post.has_voted));
  const [points, setPoints] = useState(props.post.points);
  const [posterId, setPosterId] = useState();
  const [posterHandle, setPosterHandle] = useState();
  const [posterDisplayName, setPosterDisplayName] = useState();
  const { user, signOut, showSignUpModal } = useContext(UserContext);
  useEffect(() => {
    if (props.post) {
      props.post.type === 'user' ? setPosterId(props.post.author_id) : setPosterId(props.post.tribune_id);
      props.post.type === 'user' ? setPosterDisplayName(props.post.author_display_name) : setPosterDisplayName(props.post.tribune_twitter_display_name);
      props.post.type === 'user' ? setPosterHandle(props.post.author_handle) : setPosterHandle(props.post.tribune_twitter_handle);
    }
  }, [props.post]);
  
  useEffect(() => {
    setPoints(props.post.points);
    setHasVoted(props.post.has_voted);
  }, [props.post.points, props.post.has_voted]);

  async function handleVote(voterPoints) {
    setHasVoted(true);
    setPoints(Number(points) + Number(voterPoints));
    try {
      const post = await votePost(props.post.id, user.id);
    } catch(error) {
      console.error(error.message);
      console.error(error.data);
    }
  }

  const postComponents = (
    <>
      <div className="flex flex-col flex-grow">
        <div className="flex">
          <TribunePostCardSideBar
              points={Number(points)}
              createdAt={props.post.created_at}
              hasVoted={Boolean(hasVoted)}
              onVoteClick={handleVote}
              postId={Number(props.post.id)}
              authorId={Number(posterId)}
              voteThresholdPercent={(points / (props.post.publish_points_threshold + 1)) * 100} // GIVE A REAL VALUE
          />
          <div className="px-3 md:pl-6 sm:ml-0 lg:ml-1 flex-col cursor-default rounded-r flex-grow bg-light-backdrop dark:bg-dark-backdrop py-1 sm:mr-0">
            <TribunePostCardHeading
                id={Number(props.post.id)}
                authorId={Number(posterId)}
                authorHandle={posterHandle}
                authorDisplayName={posterDisplayName}
                timestamp={props.post.created_at}
                tribuneHandle={props.post.tribune_handle}
                outsideTribune={false}
                onDeleteClick={props.onDeleteClick}
            />
            <TribunePostCardContent
                id={props.post.id}
                text={props.post.body}
                mediaUrl={props.post.media_url}
                hashtag={props.hashtag}
            />
            {props.feed && (
            <TribunePostCardBottom 
                id={props.post.id}
                authorId={Number(posterId)}
                points={Number(points)}
                hasVoted={Boolean(hasVoted)}
                timestamp={props.post.created_at}
                replyCount={props.post.reply_count}
                onDeleteClick={props.onDeleteClick}
                onVoteClick={handleVote}
                voteThresholdPercent={(points / (props.post.publish_points_threshold + 1)) * 100}
            /> )}
          </div>
        </div>
      </div>
    </>
  );
  return (
    <>
      {postComponents}
    </>
  );
}

TribunePostCard.propTypes = {
  post: PropTypes.object.isRequired,
  hashtag: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  outsideTribune: PropTypes.bool,
  feed: PropTypes.bool
};

export default TribunePostCard;
